import React, {useContext, useEffect, useRef, useState} from "react";
import CustomNavigateHeader from "../../CustomNavigateHeader/CustomNavigateHeader";
import AllMessages from "../AllMessages/AllMessages";
import "./ChatWindow.css";
import {Context} from "../../../../index";
import ChatInput from "../ChatInput/ChatInput";
import ChatService from "../../../../services/ChatService";
import {dateTransformToCSharpFormat, sendMessageAsync} from "../../../../functions/functions";
import {BeatLoader} from "react-spinners";
import {all} from "axios";

interface Interface {
    id: string;
    type: string;
    infoId: string;
}

function ChatWindow({id, type, infoId}: Interface) {
    const {store} = useContext(Context);
    const [allMessages, setAllMessages] = useState<any[]>([]);
    const [infoList, setInfoList] = useState<{ id: string, name: string }[]>([]);
    const [isGroupChat, setIsGroupChat] = useState(false)
    const [name, setName] = useState()
    const [groupName, setGroupName] = useState()
    const [customLoader, setCustomLoader] = useState(true)


    const updateChatMessage = (id: string, newText: string, latestC: any) => {
        ChatService.updateMessages(id, newText)
        const updatedChat = [...latestC];
        let newChat = updatedChat.map((item) => {
            if (item.id === id) {
                item.Message = newText
                return item
            }
            return item
        });
        let message = newChat.find((item: any) => item.id === id)
        console.log("this message needs to be updated", message)
        ChatService.sendUpdateMessages({ChatMessageId: message.id, ToUserId: message.To, Message: message.Message})
        setAllMessages(newChat)
    }
    const deleteChatMessage = (id: string, latestC: any) => {
        ChatService.deleteMessages(id);
        let message = latestC.find((item: any) => item.id === id)
        ChatService.sendDeleteMessages({ChatMessageId: message.id, ToUserId: message.To, Message: message.Message})
        setAllMessages((prevState) => {
            let newState = prevState.filter((item) => {
                return item.id !== id
            })
            return newState
        });
    }

    const iHaveAMessage = (message: any, latestC: any) => {

        if (message.messageType == 5 && message.toUserId == "group" + infoId.slice(1)) {
            if (message.fromUserId !== store.user.id) {
                const updatedChat = [...latestC];
                let datum = new Date();
                message.Date = dateTransformToCSharpFormat(datum);
                message.Message = message.message;
                message.Name = message.fromName;
                message.From = message.fromUserId;
                message.To = message.toUserId;
                message.Id = message.id;
                updatedChat.push(message);
                setAllMessages(updatedChat);
            }

        } else {
            if (":" + message.fromUserId == id) {
                const updatedChat = [...latestC];
                let datum = new Date();
                message.Date = dateTransformToCSharpFormat(datum);
                message.Message = message.message;
                message.Name = name;
                message.From = message.fromUserId;
                message.To = message.toUserId;
                message.Id = message.id;
                updatedChat.push(message);
                setAllMessages(updatedChat);

            }
        }
        ChatService.removeFromUnread({
            from: store.user.id,
            to: String(id).slice(1),
            infoId: infoId?.slice(1),
            type: Number(type?.slice(1))
        });
    }

    const iUpdateMessage = (message: any, latestC: any[]) => {
        const updatedChat = [...latestC];
        let newChat = updatedChat.map((item) => {
            if (item.id === message.chatMessageId) {
                item.Message = message.message
                return item
            }
            return item
        });
        setAllMessages(newChat)

    }

    const iDeleteMessage = (message: any, latestC: any[]) => {
        setAllMessages((prevState) => {
            let newState = prevState.filter((item) => {
                return item.id !== message.chatMessageId
            })
            return newState
        });
    }

    useEffect(() => {
        if (store.isHubOn) {
            if (type === ":5") {
                store.joinHubGroup("group" + infoId.slice(1))
            }
            store.hubConnection.off("ReceiveMessage")
            store.hubConnection.on("ReceiveMessage", (message: any) => {

                iHaveAMessage(message, allMessages);

                //console.log(updatedChat)
            });
            store.hubConnection.off("UpdateMessage")
            store.hubConnection.on("UpdateMessage", (message: any) => {

                iUpdateMessage(message, allMessages);

                //console.log(updatedChat)
            });
            store.hubConnection.off("DeleteMessage")
            store.hubConnection.on("DeleteMessage", (message: any) => {

                iDeleteMessage(message, allMessages);

                //console.log(updatedChat)
            });
        }
    }, [store.isHubOn, id, allMessages, infoId]);


    useEffect(() => {
        //store.DataLoadingON()
        setAllMessages([]);
        setCustomLoader(true);
        ChatService.chatGetMessages(
            {
                to: String(id).slice(1),
                infoId: infoId?.slice(1),
                type: Number(type?.slice(1)),
            })
            .then((response) => {
                setAllMessages(response.data.messages);
                setName(response.data.userName)
                setGroupName(response.data.groupName)
                setInfoList(response.data.infoList);
                setIsGroupChat(type === "5")
                setCustomLoader(false);
            })
            .catch(() => {
                store.ErrorON();
            })
            .finally(() => {
                //store.DataLoadingOFF()
            })
    }, [id, type, infoId])
    const EndOfChat = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (EndOfChat.current)
                EndOfChat.current.scrollIntoView({behavior: "smooth"});
        }, 500)

    }, [allMessages])

    useEffect(() => {

        console.log("All Messages: ", allMessages)
    }, [allMessages]);

    const sendMessage = async (message: string) => {
        let datum = new Date();

        const newMessage = {
            FromUser: store.user.id,
            Name: store.user.displayedName,
            From: store.user.id,
            To: String(id).slice(1),
            ToUser: String(id).slice(1),
            Message: message,
            Date: dateTransformToCSharpFormat(datum),
            id: ""
        };
        await sendMessageAsync(
            store.user.id,
            store.user.displayedName,
            message,
            String(id).slice(1),
            type && type?.slice(1),
            infoId && infoId?.slice(1)
        ).then(value => {
            newMessage.id = value;
        })
        setAllMessages((prevState) => {
            let newState = [...prevState]
            newState.push(newMessage)
            return newState
        })
    };

    const deleteMessage = async (id: string) => {

    }

    return (
        <>
            {(!store.isError && !store.isDataLoading) &&
                <>
                    {store.roles.includes('Customer') &&
                        <div className="navigate-container-from-chat">
                            <CustomNavigateHeader
                                title={name ? ("Чат с " + name) : (groupName ? "Чат \"" + groupName + "\"" : '')}
                                trashClickHandler={() => null}
                                withTrash={false}
                            />
                        </div>}
                    <div className={"chat_container"}>
                        {!customLoader ? <>
                                <div className="allMessages_container">
                                    <AllMessages
                                        chat={allMessages}
                                        isGroupChat={isGroupChat}
                                        infoList={infoList}
                                        updateChatMessage={(id: string, text: string) => {
                                            updateChatMessage(id, text, allMessages)
                                        }}
                                        deleteChatMessage={(id: string) => {
                                            deleteChatMessage(id, allMessages)
                                        }}
                                    />
                                    <div ref={EndOfChat}></div>
                                </div>
                            </>
                            :
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                height: "inherit",
                                alignItems: "center"
                            }}>
                                <BeatLoader/>
                            </div>
                        }
                        <ChatInput sendMessage={sendMessage}/>
                    </div>
                </>


            }
        </>
    );
}

export default ChatWindow;
