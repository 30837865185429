import {useLocation, useNavigate, useNavigation, useParams} from "react-router-dom";
import "./MyOrderPayment.css";
import React, {useCallback, useContext, useEffect, useState} from "react";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import Checkbox01 from "../../../../customUI/checkbox01/checkbox01";
import FunctionButton from "../../../../customUI/FunctionButton/FunctionButton";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {MyOrderPaymentModal} from "./MyOrderPaymentModal/MyOrderPaymentModal";
import {ModalContext} from "../../../../../context/ModalContext";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../../customUI/Loader/Loader";
import {Context} from "../../../../../index";


export default function MyOrderPayment(){

    const {store} = useContext(Context);

    const [openModal,setOpenModal] = useState(false);
    const [openModalFail,setOpenModalFail] = useState(false);
    const[loading,setLoading] = useState(true);
    const[paymentStatus,setPaymentStatus] = useState<number>(0);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const {modal, open, close} = useContext(ModalContext);
    const navigate = useNavigate();
    const params = useParams();
    const InvoiceId = params.id || "";
    const price = params.price || "0";
    const chosenOption = Number(params.paymentOption) || 0;

    useEffect(()=>{
        setPaymentStatus(chosenOption);
    },[])

    const postKindOfPayment = async () => {
        setLoading(false);
        if(InvoiceId)
            return CabinetBuyerService.setPaymentStatus(InvoiceId,paymentStatus)
                .then((response)=>{
                    if(response.status===200){
                        setLoading(true)
                        setOpenModal(true)
                    }
                    else{
                        setLoading(true);
                        setOpenModalFail(true);
                    }
                });
        else return store.showDialog("Cannot find InvoiceId").then(()=>{});
    };

    const PaymentOptions:{name:string;id:number;}[] =
        [{name:"Наличные",id:1},
            {name:"Банковская карта",id:2},
            {name:"Бонусы",id:3},
            {name:"Со счета",id:4},
            {name:"QR-код",id:5},
            {name:"По терминалу",id:6}];

    function ChangeHandler(paymentID:number){
        setPaymentStatus(paymentID);
    }

    const CloseModalHandler = () => {
        setOpenModal(false);
        setOpenModalFail(false);
        close();
        navigate(-1);
    }

    const PaymentOptionsList = useCallback((paymentStatus:number)=>{
        return PaymentOptions.map((payment_option) => {
            if (payment_option.id === paymentStatus) return (
                <div key={`payopt2${Math.random()}`} className={"payment-option"}>
                    <Checkbox01 checked={true} changeHandler={() => ChangeHandler(payment_option.id)}/>
                    <span className={"payment-option-description"}>{payment_option.name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>
            )
            else return (
                <div key={`payopt1${Math.random()}`} className={"payment-option"}>
                    <Checkbox01 checked={false} changeHandler={() => ChangeHandler(payment_option.id)}/>
                    <span className={"payment-option-description"}>{payment_option.name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>)
        });
    },[[],paymentStatus])


    return(
        <>
            {!loading?(
                    <Loader load={true} />):
                (<>
                    <CustomNavigateHeader
                        title={`Заказ №${InvoiceId}`}
                        trashClickHandler={()=>null}
                        withTrash={false}
                    />
                    <p id={"myorder-payment-upper-title"}>Оплата</p>
                    <span id={"myorder-payment-title"}>Как будете оплачивать?</span>
                    <div id="myorder-payment-status-list">{PaymentOptionsList(paymentStatus)}</div>
                    <div id="myorder-payment-confirm">
                        <FunctionButton text={"Подтвердить"} onClickHandler={postKindOfPayment} style={{fontSize: 13, fontWeight: 700, lineHeight:"16px", cursor: "pointer"}}/>
                    </div>
                    {openModal && <MyOrderPaymentModal
                        title={"Выбор способа оплаты"}
                        text={"Выбор произведен успешно!"}
                        onClose={CloseModalHandler}>
                </MyOrderPaymentModal>}
                    {openModalFail && <MyOrderPaymentModal
                        title={"Выбор способа оплаты"}
                        text={"Произошла ошибка! В данный момент невозможно выбрать способ оплаты для этого заказа"}
                        onClose={CloseModalHandler}>
                    </MyOrderPaymentModal>}
                </>)}
        </>
    )
}