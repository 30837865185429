import React, {useEffect, useState} from "react";
import "./MyOrdersElement.css";
import {IMyOrders} from "../../../../../models/models";
import {Link} from "react-router-dom";
import MyOrdersDropdown from "../../../../customUI/MyOrdersDropdown/MyOrdersDropdown";
import {getParsedDate, CompareDates} from "../../../../../functions/functions";
import {CustomIMG} from "../../../../customUI/CustomIMG/CustomIMG";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import FunctionButton from "../../../../customUI/FunctionButton/FunctionButton";
import {getOrderStatusName} from "../../../../../functions/Order/OrderFunctions";

interface Interface {
    props: IMyOrders;
}

export default function MyOrdersElement({props}: Interface) {
    const FunctionButtonStyle: React.CSSProperties = {
        fontSize: 10,
        borderRadius: 7,
        float: "right",
    };

    const [paymentButtonText, setPaymentButtonText] = useState("Выбрать способ оплаты");

    const PaymentOptions: { name: string; id: number; }[] = [
        {name: "Наличные", id: 1},
        {name: "Банковская карта", id: 2},
        {name: "Бонусы", id: 3},
        {name: "Со счета", id: 4},
        {name: "QR-код", id: 5},
        {name: "По терминалу", id: 6}
    ];

    const price = new Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(PriceCounter());
    const dateForDelivery = CompareDates(props.orderProducts.map((item: { startDate: any; }) => item.startDate ? item.startDate : ""));
    const location = `${props.destination?.city}, ${props.destination?.street}, ${props.destination?.number}`;
    const image = (props.seller && props.seller.image) ? (
        <CustomIMG className={"myorders-supply-img"} src={props.seller.image}/>
    ) : (<></>);

    useEffect(() => {
        let counter = props.historyStatuses[0]?.status;
        for (const key in props.historyStatuses) {
            if (counter !== undefined) {
                if (props.historyStatuses[key].status < counter)
                    counter = props.historyStatuses[key].status;
            }
        }
        for (const key2 in PaymentOptions) {
            if (PaymentOptions[key2].id === props.paymentOption) {
                setPaymentButtonText(PaymentOptions[key2].name);
            }
        }
    }, [props]);

    function PriceCounter() {
        return props.orderProducts.reduce((sum: number, item: { price: number; amount: number; }) => sum + (item.price * item.amount), 0);
    }

    return (
        <>
            <div className={"myorders-main-div"}>
                <span className={"myorders-location"}>{props.destination.destinationOption === 2 ? "Доставка" : "Самовывоз"} – {location}</span>
                <span className={"myorders-index"}>Заказ №{props.orderId}</span>
            </div>
            <div className={"myorders-main-div"}>
                <div className={`myorders-status-red ${(props.historyStatuses.every((status: { status: number; }) => ![8, 9].includes(props.historyStatuses[props.historyStatuses.length - 1].status))) && "myorders-status-green"}`}>
                    {props.historyStatuses.length > 0 && getOrderStatusName(props.historyStatuses[props.historyStatuses.length - 1].status)}
                </div>
                <Link to={SpaRoutes.MYORDER + `/${props.orderId}`}>
                    <span className={"myorders-cancel"}>{ props.historyStatuses[props.historyStatuses.length-1]?.status == 10 ? "" : "Отменить заказ"}</span>
                </Link>
            </div>
            <div className={"myorders-main-div"}>
                <span className={"myorders-date-item-string"}>
                    {getParsedDate(dateForDelivery, false, false)}
                </span>
                <span className={"myorders-amount"}>{price}</span>
            </div>
            <div className={"myorders-supplier-div"}>
                <div className={"myorders-supply-company"}>{props.seller.displayedName}
                    {image}
                </div>
                <Link to={SpaRoutes.MYORDER_PAYMENT + `/${props.orderId}/${price}/${props.paymentOption}`}>
                    <div className={"myorders-payment-button"}>
                        <FunctionButton text={paymentButtonText} onClickHandler={() => {}} style={FunctionButtonStyle}/>
                    </div>
                </Link>
            </div>
            <div className={"myorders-main-div"}>
                <MyOrdersDropdown
                    propsnext={props}
                    title={(props.orderProducts.length === 1) ? ("позиция") : ("позиции(й)")}/>
                <Link to={SpaRoutes.MYORDER + `/${props.orderId}`} state={props}>
                    <span className={"myorders-more"}>Подробнее</span>
                </Link>
            </div>
            <span className={"myorders-date"}>Дата заказа: {getParsedDate(props.startDate??"", true, false)}</span>
        </>
    );
}
