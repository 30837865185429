import React, {useContext, useRef, useState} from 'react';
import "./DeliveryPointPopup.css"
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import Checkbox01 from "../../../../customUI/checkbox01/checkbox01";
import {Context} from "../../../../../index";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import CustomInput from "../../../../customUI/CustomInput/CustomInput";
import KladrInputRegion from "../../../../customUI/CustomDropdown/KladrInputRegion";
import KladrInputCity from "../../../../customUI/CustomDropdown/KladrInputCity";
import CustomAntPopconfirm from "../../../../customUI/CustomAntPopconfirm/CustomAntPopconfirm";

interface DeliveryPointPopupProps {
    dropAddressId: string
    region: string
    onClose: () => void
    onUpdate: () => void
    data?: {
        deliveryPointId?: string
        name?: string
        city?: string
        region?: string
        street?: string
        number?: string
    }
}

const DeliveryPointPopup = ({dropAddressId, region, onClose, onUpdate, data}: DeliveryPointPopupProps) => {
    const {store} = useContext(Context);

    const [dataDP, setDataDP] = useState(data ?? {})

    const uploadDeliveryPoint = () => {
        store.DataLoadingON()
        let data = {
            DropAdressId: dropAddressId,
            DeliveryPointId: dataDP.deliveryPointId,
            Name: dataDP.name,
            City: dataDP.city,
            Street: dataDP.street,
            Number: dataDP.number
        }

        if (!data.DeliveryPointId) {
            CabinetSellerService.createDeliveryPoint(data)
                .then(() => {
                    alert("Пункт выдачи был добавлен")
                    onUpdate()
                    onClose()
                })
                .catch((e) => {
                    alert("Что-то пошло не так")
                })
                .finally(() => store.DataLoadingOFF())
        } else {
            CabinetSellerService.updateDeliveryPoint(data)
                .then(() => {
                    alert("Данные были изменены")
                    onUpdate()
                    onClose()
                })
                .catch((e) => {
                    alert("Что-то пошло не так")
                })
                .finally(() => store.DataLoadingOFF())
        }
    }

    const deleteDeliveryPoint = () => {
        let data = {
            DropAdressId: dropAddressId,
            DeliveryPointId: dataDP.deliveryPointId
        }
        store.DataLoadingON()
        CabinetSellerService.deleteDeliveryPoint(data)
            .then(() => {
                alert("Пункт выдачи был удален")
                onUpdate()
                onClose()
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => {
                store.DataLoadingOFF()
            })
    }

    const ref = useRef(null)
    const popConfirmRef = useRef(null)

    useOnClickOutside(ref, () => onClose(), [popConfirmRef])

    return (
        <div className="deliveryPointPopup-container">
            <div className="deliveryPointPopup-content" ref={ref}>
                <CustomNavigateHeader title={dropAddressId ? "Пункт выдачи" : "Добавить пункт выдачи"}
                                      onClick={() => onClose()} withTrash={false}/>
                <div className={"deliveryPointPopup-input-container"}>
                    Название
                    <CustomInput
                        value={dataDP?.name}
                        onChange={(e) => setDataDP({...dataDP, name: e.target.value})}
                        inputSize={"small"}
                    />
                </div>

                <div className={"deliveryPointPopup-input-container"}>
                    Регион
                    <KladrInputRegion
                        value={region}
                        getRegionId={(key) => setDataDP({...dataDP, region: key})}
                    />
                </div>

                <div className={"deliveryPointPopup-input-container"}>
                    Город
                    <KladrInputCity regionId={dataDP.region ?? ""}
                                    value={dataDP.city}
                                    valueSetter={(val) => setDataDP({...dataDP, city: val})}
                                    onChangeHandler={(val) => setDataDP({...dataDP, city: val})}
                    />
                </div>

                <div className={"deliveryPointPopup-street-number-container"}>
                    <div className={"deliveryPointPopup-input-container"}>
                        Улица
                        <CustomInput
                            value={dataDP?.street}
                            onChange={(e) => setDataDP({...dataDP, street: e.target.value})}
                            inputSize={"small"}
                        />
                    </div>
                    <div className={"deliveryPointPopup-input-container"}>
                        Кв/оф
                        <CustomInput
                            value={dataDP?.number}
                            onChange={(e) => setDataDP({...dataDP, number: e.target.value})}
                            inputSize={"small"}
                        />
                    </div>
                </div>
                <div className={"deliveryPointPopup-buttons-container"}>
                    {dataDP.deliveryPointId &&
                        <CustomAntPopconfirm
                            title={"Вы точно хотите удалить этот пункт выдачи?"}
                            onConfirm={() => deleteDeliveryPoint()} ref={popConfirmRef}
                        >
                            <button
                                className={"deliveryPointPopup-button-delete"}
                            />
                        </CustomAntPopconfirm>
                    }
                    <button
                        className="deliveryPointPopup-button"
                        onClick={() => uploadDeliveryPoint()}
                    >
                        {dropAddressId ? "Сохранить изменения" : "Добавить пункт выдачи"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeliveryPointPopup;