import React, {useContext, useEffect, useRef, useState} from "react";
import {Context} from "../../../index";

import CustomNavigateHeader from "../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {ICategoriesLE} from "../../../models/models";
import {ISubCategoriesLE} from "../../../models/models";
import './Catalog.css'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ICard} from "../../../models/product/productCard";
import CustomSwiper from "../../customUI/CustomSwiper/CustomSwiper";
import HomeService from "../../../services/HomeService";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import Searchbar from "../../customUI/Searchbar/Searchbar";
import {ToogleVisible} from "../../customUI/Searchbar/functions";
import FilterForCatalog from "./FilterForCatalog";
import CustomSwiperWithLazyLoading from "../../customUI/CustomSwiperWithLazyLoading/CustomSwiperWithLazyLoading";
import NoProductsPage from "../../customUI/NoProductsPage/NoProductsPage";

interface IitemArray {
    catName: string;
    catId: number;
    sorter: number;
    cards: ICard[];
    isAuth: boolean;
}

function CatalogIndex() {
    const {store} = useContext(Context);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [ansicht, setansicht] = useState<boolean>(false);
    const [neueFilter, setneueFilter] = useState<boolean>(false);
    const [searchword, setSearchword] = useState<string>("");
    const [CATArray, setCATArray] = useState<ICategoriesLE[]>([]);
    const [itemArray, setitemArray] = useState<IitemArray[]>([]);
    const [nothingShown, setNothingShown] = useState<boolean>();
    const navigate = useNavigate();
    let {search} = useParams();
    if (search === undefined) {
        search = "";
    }

    useEffect(() => {
        setSearchword(search ? search.slice(1) : "");
    })
    // const { state } = useLocation()

    const getCatList = () => {
        store.DataLoadingON()
        HomeService.catList(store.filterInfo).then(response => {
            let list: any[] | ((prevState: ICategoriesLE[]) => ICategoriesLE[]) = [];
            for (const cat of response.data) {
                let list2: any[] = [];
                for (const subcategory of cat.categories) {
                    list2.push(
                        {
                            Name: subcategory.name,
                            ID: subcategory.categoryId,
                            Sorter: subcategory.sorter
                        }
                    )
                }
                let list2sort = list2.sort((a, b) => {
                    if (a.Sorter < b.Sorter) return -1
                    if (a.Sorter > b.Sorter) return 1
                    return 0
                })
                list.push(
                    {
                        Name: cat.name,
                        ID: cat.topCategoryId,
                        Sorter: cat.sorter,
                        SubCat: list2sort
                    }
                )
            }
            let listsort = list.sort((a, b) => {
                if (a.Sorter < b.Sorter) return -1
                if (a.Sorter > b.Sorter) return 1
                return 0
            })
            setCATArray(listsort);
        }).then(() => {
            getAllCatalog()
        }).catch(() => {
            store.ErrorON()
        }).finally(() => {

        })
    }

    const getAllCatalog = () => {
        store.DataLoadingON()
        HomeService.allCatalog(store.filterInfo).then(response => {
            let items = (response.data.list.map((itemArray: IitemArray) => {
                    itemArray.cards = itemArray.cards.map((card: ICard) => {
                        card.visible = [true];
                        card.content = {Name: card.name_of_product, Id: card.productId, Seller: card.supply_company};
                        return card;
                    })
                    let oldCards = itemArray.cards;
                    itemArray.cards = ToogleVisible(searchword, ["Name", "Id", "Seller"], ["", "", ""], oldCards)
                    return itemArray;
                }
            ));
            let itemsSorted = items.sort((a: any, b: any) => {
                if (a.sorter < b.sorter) return -1
                if (a.sorter > b.sorter) return 1
                return 0
            })
            setitemArray(itemsSorted)
        })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
                store.DataLoadingOFF()
                getVisibleCards(search ? search.slice(1) : "")
            })
    }

    useEffect(() => {
        setneueFilter(true)
    }, [store.filterInfo])

    useEffect(() => {
        if ((itemArray.length === 0 || neueFilter) && ansicht) {
            getCatList()
        }
    }, [ansicht])

    const getVisibleCards = (search: string) => {
        setitemArray((prevState) => {
            let newState: IitemArray[];
            newState = prevState.map((itemArray) => {
                let oldCards = itemArray.cards;
                itemArray.cards = ToogleVisible(search, ["Name", "Id", "Seller"], ["", "", ""], oldCards)
                return itemArray
            })
            return newState;
        })
    }

    useEffect(() => {
        getVisibleCards(searchword)
    }, [searchword])

    useEffect(() => {
        if (!store.isDataLoading) {
            if (itemArray.every((item) => {
                if (item.cards.length === 0) {
                    return true
                }
                return item.cards.every((card) => {
                    return card.visible?.every(i => !i)
                })
            })) {
                setNothingShown(true);
            } else {
                setNothingShown(false);
            }
        }
    }, [itemArray, store.isDataLoading])

    const goCategory = (id: string, title: string, isMainPage: boolean) => {
        let filter = store.filterInfo
        filter.topId = `${id}`
        filter.topCatName = title
        filter.subId = "-1"
        filter.subCatName = "Подкатегория"
        if (isMainPage)
            filter.selectedIds = []
        store.FiltersSave(filter.topId, filter.topCatName, filter.subId, filter.subCatName, filter.selectedIds)
        navigate(SpaRoutes.CATEGORY + "/" + id + '/:')
    }

    const goSubCategory = (id: string, title: string, isMainPage: boolean, idCat: string, idCatTitle: string) => {
        goCategory(idCat, idCatTitle, isMainPage)
        let filter = store.filterInfo
        filter.subId = `${id}`
        filter.subCatName = title
        if (isMainPage)
            filter.selectedIds = []
        store.FiltersSave(filter.topId, filter.topCatName, filter.subId, filter.subCatName, filter.selectedIds)
        navigate(SpaRoutes.SUB_CATEGORY + '/' + id + '/:')
    }

    const CATArrayElement = CATArray.map((item: ICategoriesLE, index) => {
        return (
            <details className={"cat-header"} key={index + item.ID}>
                <summary className={"cat-summary"}>
                    <div onClick={() => goCategory(item.ID, item.Name, true)} className={"cat-summary-text"}>
                        {item.Name}
                    </div>
                </summary>
                <div className={"cat-links"}>
                    {item.SubCat.map((sub: ISubCategoriesLE, index) => {
                        return (
                            <div
                                key={index + sub.ID}
                                onClick={() => goSubCategory(sub.ID, sub.Name, true, item.ID, item.Name)}
                                className={"cat-link-box"}
                            >
                                {sub.Name}
                            </div>
                        )
                    })}
                </div>
            </details>
        )
    })
    const handleBigView = () => {
        setansicht(true);
    };
    const handleSmalView = () => {
        setansicht(false);
    };

    const [triggerResetFilter, setTriggerResetFilter] = useState(false)

    useEffect(() => {
        setTriggerResetFilter(false)
    }, [triggerResetFilter]);

    const changeHandler = (text: string) => {
        //setSearchword(text);
        navigate(SpaRoutes.CATALOG_INDEX + `/:${text}`)
        //setTriggerResetFilter(true)

        //search = text
        setansicht(false);
    }

    const ListKategories: any = () => {
        var list = [];
        for (let i = 0; i < itemArray.length; i++) {
            let displayed = itemArray[i].cards.filter((card) => {
                return card.visible?.every(i => i)
            })
            if (displayed.length > 0) {
                list.push(
                    <CustomSwiperWithLazyLoading
                        key={i + itemArray[i].catId}
                        data={displayed}
                        title={itemArray[i].catName}
                        toSub={false}
                        id={itemArray[i].catId.toString()}
                        isAuth={(store.roles.includes('Customer') || store.roles.includes('Dealer'))}
                        searchWord={searchword}
                    />
                )
            }

        }
        return (list)
    }

    return (
        <>
            <div id={"CatSelect"} data-isadmin={store.roles.includes('Admin')}>
                <div id={"CatSelectBox"}>
                    {/*<CustomNavigateHeader title={`Каталог`}/>*/}
                    <div className="sticky-search-filter" data-isauth={store.isAuth && store.roles.includes('Dealer')}>
                        <div className={"catalog_searchBox"}>
                            <Searchbar
                                changeHandler={changeHandler}
                                initialText={"Искать"}
                                value={search.slice(1)}
                            />
                        </div>

                        <div id={"viewSelectorBox"}>
                            <div style={{marginRight: "auto"}}>
                                <FilterForCatalog
                                    triggerResetFilter={triggerResetFilter}
                                    searchword={searchword}
                                    onUpdate={getCatList}
                                />
                            </div>
                            <div className={"viewSelectorItem"} onClick={() => {
                                handleBigView()
                            }}>
                                <img className={ansicht ? 'view-selector-shadow' : ''} id={"menu-img"}
                                     src={"/Pictures/Иконка - строчки.svg"}/>
                            </div>
                            <div className={"viewSelectorItem"} onClick={() => handleSmalView()}>
                                <img className={!ansicht ? 'view-selector-shadow' : ''} id={"menu-img"}
                                     src={"/Pictures/Иконка - слайдер.svg"}/>
                            </div>
                        </div>
                    </div>

                    {!store.isDataLoading &&
                    nothingShown ?
                        <NoProductsPage
                            tittle={"Не смогли ничего для вас найти"}
                            text={"Не нашли по вашему запросу ни одного продукта, попробуйте изменить параметры фильтра или очистить его"}
                            searchWord={searchword}
                        />
                        : ansicht ?
                            <div style={{marginTop: "15px"}}>
                                {CATArrayElement}
                            </div>
                            :
                            <div id={"swiper-container-catalog"}>
                                {ListKategories && <ListKategories/>}
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default CatalogIndex;

