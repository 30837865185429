import React from 'react';
import {Table, TablePaginationConfig} from 'antd';
import './CustomAntTable.css';

interface CustomAntTableProps {
    data: any[],
    columns: any,
    fontSize?: string,
    pagination?: false | TablePaginationConfig,
    expandable?: {
        expandedRowRender?: (record: any) => React.ReactNode,
        defaultExpandAllRows?: boolean,
        showExpandColumn?: boolean
    },
    footer?: any,
    scroll?: {x: any; y: any},
}

const CustomAntTable = ({ data, columns, fontSize = '14px', pagination = false, expandable, footer, scroll }: CustomAntTableProps) => {
    return (
        <div>
            <Table
                className="custom-ant-table"
                columns={columns}
                dataSource={data}
                pagination={pagination}
                scroll={scroll??{ x: "max-content" }}
                expandable={expandable}
                footer={footer}
            />
        </div>
    );
};

export default CustomAntTable;
