import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Context} from "../../../../index";
import ProductCard from "../../../customUI/ProductCard/ProductCard";
import SwiperCore, {FreeMode, Navigation, Pagination} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";

function HistoryViewed() {
    const [history, setHistory] = useState({})
    const {store} = useContext(Context)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(  () => {
        store.DataLoadingON()
        if (store.roles.find((role: any) => role.includes('Customer')))
            CabinetBuyerService.historyViewed(true).then(data => {
                setHistory(data.data)
            })
                .then(()=> {

                })
            .catch(()=> {
                store.ErrorON()
            })
            .finally(()=> {
                store.DataLoadingOFF()
            })
        else {

        }

    }, [])



    SwiperCore.use([Navigation]);

    function HistorySwiper(props: any) {
        const { history } = props;
        const historyArray = Object.values(history);

        return (
            <Swiper
                breakpoints={{
                    // when window width is <1100px (480px)
                    0: {
                        slidesPerView: 2.3, //подобрано для ширины окна 140px;
                    },
                    // when window width is 1100px<w<1600px (1366px)
                    1100: {
                        slidesPerView: 5.637, //Подобрано таким образом, чтобы div одного слайда был 207px
                    },
                    // when window width is 1600px<w<1920px (1920px)
                    1600: {
                        slidesPerView: 6,
                    },
                }}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
            >
                {historyArray.map((history: any, index) => {
                    return (
                        <SwiperSlide key={index + history.productId}>
                            <ProductCard
                                key={index + history.productId}
                                isAuth={store.isAuth}
                                item={history}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        );
    }

    return (
        <>
            {(!store.isError && !store.isDataLoading ) &&
                <>
                    <div className="navigate">
                        <CustomNavigateHeader
                            title={"История просмотров"}
                            trashClickHandler={()=>null}
                            withTrash={false}
                        />
                    </div>
                    {store.roles.find((role) => role.includes('Customer'))&&<>
                        <div style={{marginTop:"30px"}} className="historyPage-container">
                            <h3 className="history-head">Раннее вы смотрели:</h3>
                            <HistorySwiper history={history}/>
                        </div>
                    </>
                    }
                </>
            }
        </>
)
}

export default observer(HistoryViewed)