import { Image } from 'antd'
import React, { FC, useContext } from 'react'
import { Context } from '../../../index'
import type { IGalleryImages } from '../../../models/response/getProductInfoResponse'
import './GalleryThumbnails.css'

interface IGalleryThumbnails {
	galleryImages: IGalleryImages[] | undefined;
	mainImage: string;
	setMainImage: React.Dispatch<React.SetStateAction<string>>
}

export const GalleryThumbnails: FC<IGalleryThumbnails> = ({ galleryImages, mainImage, setMainImage }) => {
	const { store } = useContext(Context)
	return (
		<>
			{galleryImages?.map((item, index) => {
					return (
						<div
							className={mainImage === store.imgServer + item.imageUrl ? 'gallery-thumbnail gallery-thumbnail_active' : 'gallery-thumbnail'}
							onClick={() => setMainImage(store.imgServer + item.imageUrl)}
							key={index}>
							<Image
								src={store.imgServer + item.smallImageUrl}
								preview={false}
								fallback={'/Pictures/supplierNoImage.svg'}
							/>
						</div>
					)
				}
			)}
		</>
	)
}