import React, {useContext, useEffect, useState} from "react";
import "./SupplierChat.css"
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import ChatService from "../../../../services/ChatService"
import ChatWindowItem from "../../../customUI/Chat/ChatWindowItem/ChatWindowItem";
import {ChatWindowType} from "../../../../models/Chat/Chat"
import ChatWindow from "../../../customUI/Chat/ChatWindow/ChatWindow";
import {SpaRoutes} from "../../../../Routes/spaRoutes";

function SupplierChat() {
    const {store} = useContext(Context);
    const navigate = useNavigate()
    const [chatWindowItems, setChatWindowItems] = useState<ChatWindowType[]>([]);
    let {id, type, infoId} = useParams();
    if(id === undefined) id=""
    if(type === undefined) type="0"
    if(infoId === undefined) infoId=""


    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(() => {
        store.DataLoadingON();
        ChatService.chatGeneral()
            .then(result => {
                setChatWindowItems(result.data)
        })
            .catch(()=>{
                store.ErrorON();
            })
            .finally(()=>{
                store.DataLoadingOFF();
            })
    }, []);

    useEffect(() => {
        if (store.isHubOn) {
            store.hubConnection.on("UpdateCounter", (forChat: string) => {
                if ("group:" === forChat.substring(0,6)){
                    if(!window.location.href.includes(forChat.slice(6))) {
                        setChatWindowItems(prevState => {
                            let newState = [...prevState]
                            newState.map(item => {
                                if (item.infoId === forChat.slice(6) && item.infoId !== infoId?.slice(1)) {
                                    item.counter = item.counter + 1;
                                }
                                return item;
                            })
                            return newState;
                        })
                    }
                }
                else{
                    if(!window.location.href.includes(forChat)) {
                        setChatWindowItems(prevState => {
                            let newState = [...prevState]
                            newState.map(item => {
                                if (item.userId === forChat && item.userId !== id?.slice(1)) {
                                    item.counter = item.counter + 1;
                                }
                                return item;
                            })
                            return newState;
                        })
                    }
                }

            });
        }
    }, [store.isHubOn]);

    const clickChatWindowItem = (chat: ChatWindowType) => {
        setChatWindowItems(prevState => {
            let newState = [...prevState]
            newState.map(item=>{
                    item.focus =
                        item.userId === chat.userId &&
                        item.type === chat.type &&
                        item.infoId === chat.infoId;
                    if (item.focus === true){
                        item.counter = 0;
                    }
                    return item;
            })
            return newState;
        })
        navigate(SpaRoutes.SUPPLIER_CHAT + `/:${chat.userId}/:${chat.type}/:${chat.infoId}`)
    }
    const sortedChats = chatWindowItems.sort((a: { type: number; }, b: { type: number; }) => a.type - b.type);

    let list1:any[] = [];
    let list5:any[] = [];
    let AdminComponent:any;

    let unreadTotal = chatWindowItems.reduce((count,item)=>{
        return count+item.counter
    },0)

    const chatWindowItemsElem = sortedChats.map(chat => {
        if(chat.type === 1){
            if(chat.userId === store.AdminId)
                AdminComponent = <div className="supplier_ChatWindowItem">
                    <ChatWindowItem
                        unreadTotal={unreadTotal}
                        unread={chat.counter}
                        key={chat.user.id}
                        counter={chat.counter}
                        lastMessage={chat.lastMessage}
                        user={chat.user}
                        role="Dealer"
                        onClick={() => clickChatWindowItem(chat)}
                        focus={chat.focus}
                        infoText={chat.infoText}
                        infoId={chat.infoId}
                        type={chat.type}
                    />
                </div>
            else
            list1.push(
                <div className="supplier_ChatWindowItem">
                    <ChatWindowItem
                        unreadTotal={unreadTotal}
                        unread={chat.counter}
                        key={chat.user.id}
                        counter={chat.counter}
                        lastMessage={chat.lastMessage}
                        user={chat.user}
                        role="Dealer"
                        onClick={() => clickChatWindowItem(chat)}
                        focus={chat.focus}
                        infoText={chat.infoText}
                        infoId={chat.infoId}
                        type={chat.type}
                    />
                </div>
            )
        }
        if(chat.type === 5){
            list5.push(
                <div className="supplier_ChatWindowItem">
                    <ChatWindowItem
                        unreadTotal={unreadTotal}
                        unread={chat.counter}
                        key={chat.user.id}
                        counter={chat.counter}
                        lastMessage={chat.lastMessage}
                        user={chat.user}
                        role="Dealer"
                        onClick={() => clickChatWindowItem(chat)}
                        focus={chat.focus}
                        infoText={chat.infoText}
                        infoId={chat.infoId}
                        type={chat.type}
                    />
                </div>
            )
        }
    });


    return (
        <>
            {(!store.isError && !store.isDataLoading) &&
                <>
                    {/*<div><CustomNavigateHeader title={"Чаты"} withTrash={false}/></div>*/}
                    <div className="supplier_chat_window">
                        <div className="supplier_left_menu_chat">
                            <div id={'supplier-chat-scrollbar'} className="supplier_ChatWindowItem_container">
                                {list1.length>0 &&
                                    <>
                                        <div className={"supplier_left_menu_chat_title"}>Личные чаты</div>
                                        {list1.map(chat => chat)}
                                    </>
                                }
                                {list5.length>0 &&
                                    <>
                                        <div className={"supplier_left_menu_chat_title"}>Групповые чаты</div>
                                        {list5.map(chat => chat)}
                                    </>
                                }
                                {AdminComponent &&
                                    <>
                                        <div className={"supplier_left_menu_chat_title"}>Администратор</div>
                                        {AdminComponent}
                                    </>
                                }
                            </div>
                        </div>
                        <div className="supplier_chat_right_container">
                            <ChatWindow
                                id={id}
                                type={type}
                                infoId={infoId}
                            />
                        </div>

                            {/*<AllMessages*/}
                            {/*    chat={allMessages}*/}
                            {/*    newMessage={chat}*/}
                            {/*    infoList={infoList}*/}
                            {/*    isGroupChat={isGroupChat}*/}
                            {/*/>*/}
                            {/*<ChatInput sendMessage={sendMessage}/>*/}
                    </div>
                </>
            }
        </>
    );
}

export default observer(SupplierChat);
