import {useEffect, useState} from "react";
import ChooseMonthFromDate from "../Calendar/ChooseMonthFromDate";
import "../CalendarReklama/CalendarReklama.css"
import HomeService from "../../../services/HomeService";
import {ICalendarEvents, ICalendarReklamaEvents, IReklama, IReservation} from "../../../models/models";

interface ICalendar {
    calendarEvent: ICalendarReklamaEvents[];
    itemEventClick: (day: number, month: number, year: number, reservations: IReservation[]) => void;
    itemClick?: () => void;
    updateDropdown?: (month: number, year: number) => void;
    userReklama: IReklama[],
    type: number,
    selectRegion: string
}

export default function AdminCalendarReklama({
                                                 calendarEvent,
                                                 itemEventClick,
                                                 updateDropdown,
                                                 itemClick,
                                                 type,
                                                 selectRegion
                                             }: ICalendar) {
    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
    const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());
    const [currentCity, setCurrentCity] = useState("");
    const currentMonthDates = getMonthDates(currentMonth, currentYear);

    console.log(calendarEvent)

    function goToPreviousMonth() {
        if (currentMonth - 1 == -1) {
            if (updateDropdown) {
                updateDropdown(12, currentYear - 1)
            }
            setCurrentMonth(11);
            setCurrentYear((prevYear) => prevYear - 1);
        } else {
            if (updateDropdown) {
                updateDropdown(currentMonth, currentYear)
            }
            setCurrentMonth((prevMonth) => prevMonth - 1);
        }
    }

    function goToNextMonth() {
        if (currentMonth + 1 == 12) {
            if (updateDropdown) {
                updateDropdown(1, currentYear + 1)
            }
            setCurrentMonth(0);
            setCurrentYear((prevYear) => prevYear + 1);
        } else {
            if (updateDropdown) {
                updateDropdown(currentMonth + 2, currentYear)
            }
            setCurrentMonth((prevMonth) => prevMonth + 1);

        }
    }

    function getMonthDates(month: any, year: any) {
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const firstDay = new Date(year, month, 1).getDay();
        const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;
        const monthDates = [];
        for (let i = 1; i <= daysInMonth; i++) {
            monthDates.push(new Date(year, month, i));
        }
        for (let i = 0; i < adjustedFirstDay; i++) {
            monthDates.unshift(null);
        }
        return monthDates;
    }

    function handleDateClick(date: any) {
        setSelectedDate(date);
    }

    let date = new Date()

    function DisabledDay(day: number, month: number, year: number,) {
        let res = false
        calendarEvent.map((item) => {

            if (item.day == day && item.month == month + 1 && item.year == year && item.reservations.length == 0) {
                res = true
            }
        })


        return (res)
    }

    function CalendarClickEvent(day: number, month: number, year: number,) {
        let item = calendarEvent.find(e => e.day === day + 1)

        if (item?.reservations && item?.reservations.length > 0) {
            itemEventClick(item.day, item.month, item.year, item.reservations)
        }
    }

    const DaysOfWeek = () => {
        const res = daysOfWeek.map((day, index) => (
            <th key={index} style={{padding: "0px"}}>
                <div className={index == 5 ? "СBackgroundColorSB" : ""}>
                    <div className={index == 0 ? "CDayOfTheWeekTh CDayOfTheWeekThPN"
                        : index == 5 ? "CDayOfTheWeekTh CDayOfTheWeekThSB"
                            : index == 6 ? "CDayOfTheWeekTh CDayOfTheWeekThVSK"
                                : "CDayOfTheWeekTh"}>
                        {daysOfWeek[(index + 1) % 7]}
                    </div>
                </div>
            </th>
        ))
        return res
    }

    const isResFirstSlide = (day: number, month: number, year: number) => {
        if (selectRegion !== "global") {
            let item = calendarEvent.find((e) => e.day == day && e.month == month + 1 && e.year == year)?.reservations.find(r => r.position === 1)

            if (item && type !== 1) {
                return true
            }
        }
    }

    const isResAllText = (day: number, month: number, year: number) => {
        let targetDate = new Date(year, month, day);
            let item = calendarEvent.find(e => e.day === day + 1)

            if (item?.reservations && item?.reservations.length > 0) {
                return "Есть бронирования"
            }
    }

    const Monthes = () => {
        const res = currentMonthDates.map((date, index) => {
            if (index % 7 === 0) {
                return (
                    <tr key={index}>
                        {currentMonthDates.slice(index, index + 7).map((date, index) => (
                            <td
                                key={index}
                                onClick={() => date && handleDateClick(date)}
                                id="CDay"
                                style={{padding: "0"}}
                            >
                                <div
                                    onClick={() => {
                                        if (date)
                                            CalendarClickEvent(Number(date.getDate()), currentMonth, currentYear)
                                    }}
                                    className={`CalendarReklamaItem ${date && " CRItemActive"}`}
                                >
                                    <div
                                        className={date && DisabledDay(Number(date.getDate()), currentMonth, currentYear)
                                            ? "CrBgEvent"
                                            : ""}
                                    >
                                        <div></div>
                                        <span className={"CalendarReklama-font"}>
                                            {date ? date.getDate() : ''}
                                        </span>

                                        <div className={"CrBgEvent-hint"}>
                                            {date && isResAllText(Number(date.getDate()), currentMonth, currentYear)}
                                        </div>
                                    </div>
                                    {date && isResFirstSlide(Number(date.getDate()), currentMonth, currentYear) &&
                                        <img src={"/Pictures/starIcon.svg"}/>}

                                </div>
                            </td>
                        ))}
                    </tr>
                );
            }
        })
        return (res)
    }

    return (
        <div className={"CalendarReklamaWrapper"}>
            <div className={"CalendarReklamaContainer"}>
                <div className={"CLocationWrapper"}>
                    <div className={"CimgLocationWrapper"}>
                        <img id={"CimgLocation"} src="/Pictures/ИконкаЛокации.svg"/>
                    </div>
                    <span id={"CLocationText"} style={{marginTop: "auto", marginBottom: "auto"}}>
                        {currentCity}
                    </span>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex"}} onClick={goToPreviousMonth}>
                        <img className={"CimgChevron CimgChevronLeft"} src="/Pictures/chevron-left.svg"/>
                    </div>
                    <span id={"CTextMonth"} style={{marginTop: "auto", marginBottom: "auto", textAlign: "center"}}>
                    {ChooseMonthFromDate(currentMonth.toString())} {currentYear}
                </span>
                    <div style={{display: "flex"}} onClick={goToNextMonth}>
                        <img className={"CimgChevron CimgChevronRight"} src="/Pictures/chevron-right.svg"/>
                    </div>
                </div>
                <div className={"CEmptyDiv"}></div>
            </div>

            <table style={{borderCollapse: "collapse"}}>
                <thead>
                <tr>
                    {DaysOfWeek()}
                </tr>
                </thead>
                <tbody className={"CTBody"}>
                {Monthes()}
                </tbody>
            </table>
        </div>
    );
}