import React, {useContext, useEffect, useState} from "react";
import "../MyOrdersActive/MyOrdersActive.css";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {Context} from "../../../../../index";
import Searchbar from "../../../../customUI/Searchbar/Searchbar";
import CustomAntTable from "../../../../customUI/CustomAntTable/CustomAntTable";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {useNavigate} from "react-router-dom";
import "./MyOrdersClosed.css"

interface Order {
    orderId: string;
    seller: {
        displayedName: string;
    };
    orderProducts: Array<{
        name: string;
        amount: number;
        price: number;
    }>;
    startDate: string;
}

const MyOrdersClosed: React.FC = () => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const [data, setData] = useState<Order[]>([]);
    const [originalData, setOriginalData] = useState<Order[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [startDate, setStartDate] = useState<string>(""); // Для фильтрации начала периода
    const [endDate, setEndDate] = useState<string>(""); // Для фильтрации конца периода

    // Определение столбцов для новой структуры данных
    const columns = [
        {
            title: "Номер заказа",
            dataIndex: "orderId",
            render: (text: string, record: Order) => (
                <div className="orders-table-orderId">
                    № {record.orderId}
                    <img
                        alt="Открыть заказ"
                        className="orders-table-actionImg"
                        src="/Pictures/invoicetablelink.svg"
                        onClick={() => navigate(SpaRoutes.MYORDER + "/" + record.orderId)}
                    />
                </div>
            ),
        },
        {
            title: "Поставщик",
            dataIndex: "seller",
            render: (seller: Order["seller"]) => seller.displayedName
        },
        {
            title: "Дата создания",
            dataIndex: "startDate",
            render: (date: string) => new Date(date).toLocaleDateString("ru-RU")
        },
        {
            title: "Товары",
            dataIndex: "orderProducts",
            render: (products: Order["orderProducts"]) => {
                // Показать до двух товаров, остальные отображать как "и еще X товаров"
                if (products.length === 1) {
                    return products[0].name;
                } else if (products.length === 2) {
                    return `${products[0].name}, ${products[1].name}`;
                } else if (products.length > 2) {
                    return `${products[0].name}, ${products[1].name} и еще ${products.length - 2} товаров`;
                }
                return "-";
            }
        },
        {
            title: "Сумма",
            dataIndex: "orderProducts",
            render: (products: Order["orderProducts"]) =>
                `${products.reduce((total, item) => total + item.amount * item.price, 0).toFixed(2)} ₽`
        }
    ];

    // Функция для получения данных
    const fetchOrdersData = async () => {
        store.DataLoadingON();
        try {
            const response = await CabinetBuyerService.getMyOrders(false);
            setOriginalData(response.data);
            setData(response.data);
        } catch (error) {
            console.error("Ошибка при загрузке данных:", error);
        } finally {
            store.DataLoadingOFF();
        }
    };

    // Обработка фильтрации по поисковому запросу и датам
    const applyFilters = () => {
        let filteredData = originalData.filter(order =>
            order.orderId.includes(searchTerm) ||
            order.seller.displayedName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.orderProducts.some(product =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );

        if (startDate) {
            filteredData = filteredData.filter(order =>
                new Date(order.startDate) >= new Date(startDate)
            );
        }

        if (endDate) {
            filteredData = filteredData.filter(order =>
                new Date(order.startDate) <= new Date(endDate)
            );
        }

        setData(filteredData);
    };

    const handleSearchChange = (search: string) => {
        setSearchTerm(search);
    };

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(e.target.value);
    };

    const clearDateFilters = () => {
        setStartDate("");
        setEndDate("");
    };

    // Итоговая сумма
    const totalSum = data.reduce((sum, order) => {
        return sum + order.orderProducts.reduce((orderSum, product) => orderSum + product.amount * product.price, 0);
    }, 0).toFixed(2);

    // Обновление данных при изменении фильтров
    useEffect(() => {
        fetchOrdersData();
    }, []);

    useEffect(() => {
        applyFilters();
    }, [searchTerm, startDate, endDate]);

    return (
        <div className="myOrdersClosed">
            <CustomNavigateHeader title="Завершенные заказы" withTrash={false} />

            <div className="myOrdersClosed-filters">
                <div className="myOrdersClosed-date-filters">
                    <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        placeholder="Дата начала"
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        placeholder="Дата конца"
                    />
                    <button onClick={clearDateFilters}>Очистить даты</button>
                </div>

                <Searchbar changeHandler={handleSearchChange} initialText="Найти заказ..." />
            </div>

            <CustomAntTable
                data={data}
                columns={columns}
                pagination={{pageSize: 10}}
                footer={() => <div>Итоговая сумма: {totalSum} ₽</div>}
            />
        </div>
    );
};

export default MyOrdersClosed;
