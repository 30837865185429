import React, { useState, useEffect } from "react";
import './WarehouseFilterBox.css'; // Стили для компонента

interface OrderDropAddress {
    city: string;
    street: string;
    number: string;
}

interface Order {
    orderId: string;
    orderDropAddress: OrderDropAddress;
    startDate: string;
    customerName: string;
    price: number;
    payed: boolean;
    paymentOption: number,
    destination: { startDate: string };
    comment: string;
    action: any;
}

interface IWarehouse {
    city: string;
    warehouses: string[];
}

interface WarehouseFilterBoxProps {
    orders: Order[];
    onFilter: (filteredOrders: Order[]) => void;
}

const WarehouseFilterBox: React.FC<WarehouseFilterBoxProps> = ({ orders, onFilter }) => {
    const [citiesWithWarehouses, setCitiesWithWarehouses] = useState<IWarehouse[]>([]);
    const [selectedCity, setSelectedCity] = useState<string | null>(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState<string | null>(null);

    useEffect(() => {
        const citiesMap: IWarehouse[] = [];

        orders.forEach((order) => {
            const { city, street, number } = order.orderDropAddress;
            const warehouse = `${street} ${number}`;
            const existingCity = citiesMap.find((c) => c.city === city);

            if (existingCity) {
                if (!existingCity.warehouses.includes(warehouse)) {
                    existingCity.warehouses.push(warehouse);
                }
            } else {
                citiesMap.push({ city, warehouses: [warehouse] });
            }
        });

        setCitiesWithWarehouses(citiesMap);
    }, [orders]);

    const handleCitySelect = (city: string) => {
        setSelectedCity(city);
        setSelectedWarehouse(null);
        const filteredOrders = orders.filter((order) => order.orderDropAddress.city === city);
        onFilter(filteredOrders);
    };

    const handleWarehouseSelect = (warehouse: string) => {
        setSelectedWarehouse(warehouse);
        if (selectedCity) {
            const filteredOrders = orders.filter(
                (order) =>
                    order.orderDropAddress.city === selectedCity &&
                    `${order.orderDropAddress.street} ${order.orderDropAddress.number}` === warehouse
            );
            onFilter(filteredOrders);
        }
    };

    const resetSelection = () => {
        setSelectedCity(null);
        setSelectedWarehouse(null);
        onFilter(orders); // Возвращаем изначальный список заказов
    };

    return (
        <div className="warehouse-filter-box">
            <div className={"warehouse-filter-box-title"}>
                Склады
            </div>

            {selectedCity ? (
                <div className="selection-display">
                    <div className="selected-city">
                        {selectedCity}
                        <span className="reset-icon" onClick={resetSelection}>&times;</span>
                    </div>
                    <div className="warehouse-filter">
                        {citiesWithWarehouses
                            .find((city) => city.city === selectedCity)
                            ?.warehouses.map((warehouse) => (
                                <div
                                    key={warehouse}
                                    className={`warehouse-item ${warehouse === selectedWarehouse ? 'active' : ''}`}
                                    onClick={() => handleWarehouseSelect(warehouse)}
                                >
                                    {warehouse}
                                </div>
                            ))}
                    </div>
                </div>
            ) : (
                // Список городов
                <div className="city-filter">
                    {citiesWithWarehouses.map((city) => (
                        <div
                            key={city.city}
                            className={`city-item ${city.city === selectedCity ? 'active' : ''}`}
                            onClick={() => handleCitySelect(city.city)}
                        >
                            {city.city}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default WarehouseFilterBox;
