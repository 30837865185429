import React, {useEffect, useState} from "react";
import "./OrderFormationCalendar.css";
import Calendar from "./Calendar";
import ChooseMonthFromDate from "./ChooseMonthFromDate";
import {TimeFrom} from "../../../../../data/OrderDeliveryChoose/TimeFrom";
import {TimeTo} from "../../../../../data/OrderDeliveryChoose/TimeTo";
import CustomDropdown from "../../../../customUI/CustomDropdown/CustomDropdown";
import {deliveryDateTransform} from "../../Cart/functions";

interface Interface {
    SetTime: (orderId: string | undefined, from: string, until: string) => void;
    SetComment: (supplierId: string | undefined, comment: string) => void;
    name: string | undefined;
    orderId: string | undefined;
    initialComment:string;
    initialFrom:string;
    initialTo:string;
    selectedTime:boolean;
}

export default function OrderFormationCalendar({SetTime,SetComment, name, initialComment, orderId,initialFrom,initialTo,selectedTime}: Interface) {
// export default function OrderFormationCalendar() {
    const [showModal, setShowModal] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState('date');
    const [deliveryDay, setDeliveryDay] = useState('day');
    const [deliveryMonth, setDeliveryMonth] = useState('month');
    const [deliveryTimeFrom, setDeliveryTimeFrom] = useState('TimeFrom');
    const [deliveryTimeTo, setDeliveryTimeTo] = useState('TimeTo');
    const [isDaytime, setIsDaytime] = useState(false);
    const [comment, setThisComment] = useState(initialComment);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleDaytimeClick = () => {
        setIsDaytime(true);
        setDeliveryTimeFrom("00:00");
        setDeliveryTimeTo("");
        const deliveryString = deliveryDateTransform(new Date(deliveryDate)) + "00:00:00";
        SetTime(orderId, deliveryString, ""); // Передаем только начало периода
    };

    const handleChooseTimeClick = () => {
        setIsDaytime(false);
        setDeliveryTimeFrom("08:00");
        setDeliveryTimeTo("09:00");
        const deliveryStringFrom = deliveryDateTransform(new Date(deliveryDate)) + "08:00:00";
        const deliveryStringTo = deliveryDateTransform(new Date(deliveryDate)) + "09:00:00";
        SetTime(orderId, deliveryStringFrom, deliveryStringTo); // Передаем оба времени
    };

    const handleTimeFromSelection = (value: string) => {
        setDeliveryTimeFrom(value);
        let formattedFrom = value.length < 5 ? "0" + value : value;
        let formattedTo = deliveryTimeTo.length < 5 ? "0" + deliveryTimeTo : deliveryTimeTo;

        const deliveryStringFrom = deliveryDateTransform(new Date(deliveryDate)) + formattedFrom + ":00";
        const deliveryStringTo = isDaytime ? "" : deliveryDateTransform(new Date(deliveryDate)) + formattedTo + ":00";
        SetTime(orderId, deliveryStringFrom, deliveryStringTo);
    };

    const handleTimeToSelection = (value: string) => {
        setDeliveryTimeTo(value);
        let formattedFrom = deliveryTimeFrom.length < 5 ? "0" + deliveryTimeFrom : deliveryTimeFrom;
        let formattedTo = value.length < 5 ? "0" + value : value;

        const deliveryStringFrom = deliveryDateTransform(new Date(deliveryDate)) + formattedFrom + ":00";
        const deliveryStringTo = isDaytime ? "" : deliveryDateTransform(new Date(deliveryDate)) + formattedTo + ":00";
        SetTime(orderId, deliveryStringFrom, deliveryStringTo);
    };

    const handleCommentInput = (event: any) => {
        setThisComment(event.target.value);
    };

    const handleOkButtonClick = () => {
        // Handle delivery time selection and comment submission here
        SetComment(orderId, comment);
        toggleModal();
    };

    function handleDateSelect(date: Date | null) {
        if (!date) {
            setDeliveryDate("");
            setDeliveryDay("day");
            setDeliveryMonth("month");
            setDeliveryTimeFrom("08:00"); // Установка значений по умолчанию
            setDeliveryTimeTo("09:00");
            return;
        }

        setDeliveryDate(date.toString());
        setDeliveryDay(date.getDate().toString());
        setDeliveryMonth(date.getMonth().toString());

        let formattedFrom = deliveryTimeFrom.length < 5 ? "0" + deliveryTimeFrom : deliveryTimeFrom;
        let formattedTo = deliveryTimeTo.length < 5 ? "0" + deliveryTimeTo : deliveryTimeTo;

        // Если режим "в течение дня" активен, обнуляем значение `to`
        if (isDaytime) {
            const deliveryStringFrom = deliveryDateTransform(date) + "00:00:00";
            SetTime(orderId, deliveryStringFrom, "");
        } else {
            const deliveryStringFrom = deliveryDateTransform(date) + formattedFrom + ":00";
            const deliveryStringTo = deliveryDateTransform(date) + formattedTo + ":00";
            SetTime(orderId, deliveryStringFrom, deliveryStringTo);
        }
    }

    useEffect(() => {
        if (initialFrom) {
            const date = new Date(initialFrom);
            setDeliveryDate(date.toString());
            setDeliveryDay(date.getDate().toString());
            setDeliveryMonth(date.getMonth().toString());

            const hoursFrom = date.getHours();
            setDeliveryTimeFrom(hoursFrom + ":00");

            if (initialTo) {
                const hoursTo = new Date(initialTo).getHours();
                setDeliveryTimeTo(hoursTo + ":00");
                setIsDaytime(hoursFrom === 0 && hoursTo === 23);
            } else {
                setDeliveryTimeTo("");
                setIsDaytime(true); // Устанавливаем режим "в течение дня"
            }
        } else {
            // Значения по умолчанию при отсутствии `initialFrom`
            const currentDate = new Date();
            setDeliveryDate(currentDate.toString());
            setDeliveryDay(currentDate.getDate().toString());
            setDeliveryMonth(currentDate.getMonth().toString());
            setDeliveryTimeFrom(TimeFrom[0].name);
            setDeliveryTimeTo(TimeTo[0].name);
        }
    }, [initialFrom, initialTo]);



    function OfcTwoDropdowns() {
        return (
            <>
                <div className={!isDaytime
                    ? 'ofcTextChooseDeliveryWrapper1'
                    : 'ofcTextChooseDeliveryWrapper2'}>
                    <span id={"ofcTextChooseDelivery"}>Выберете время доставки:</span>
                </div>
                <div className="ofcDeliveryTimeSelection">

                    {!isDaytime && (
                        <>
                            <div className={"ofcTwoDropdowns"}>
                                <span>C</span>
                                <div className={"ofcDropdownWrapper"}>
                                    {/*<select className={"ofcDropdown"} onChange={handleTimeFromSelection}>*/}
                                    {/*    {TimeFrom.map((option, index) => (*/}
                                    {/*        <option key={index} value={option}>*/}
                                    {/*            {option}*/}
                                    {/*        </option>*/}
                                    {/*    ))}*/}
                                    {/*</select>*/}
                                    <CustomDropdown
                                        options={TimeFrom}
                                        value={deliveryTimeFrom}
                                        valueSetter={setDeliveryTimeFrom}
                                        onChangeHandler={handleTimeFromSelection}
                                    />
                                </div>
                                <span>До</span>
                                <div className={"ofcDropdownWrapper"}>
                                    {/*<select className={"ofcDropdown"} onChange={handleTimeToSelection}>*/}
                                    {/*    {TimeTo.map((option, index) => (*/}
                                    {/*        <option key={index} value={option}>*/}
                                    {/*            {option}*/}
                                    {/*        </option>*/}
                                    {/*    ))}*/}
                                    {/*</select>*/}
                                    <CustomDropdown
                                        options={TimeTo}
                                        value={deliveryTimeTo}
                                        valueSetter={setDeliveryTimeTo}
                                        onChangeHandler={handleTimeToSelection}
                                    />
                                </div>
                            </div>
                            <div className={"ofcTextOr"}>
                                <span>Или</span>
                            </div>
                        </>
                    )}
                    <div className={"ofcButtonsDelivery"}>
                        <div className={!isDaytime ? 'ofcButton1DuringTheDay' : 'ofcButton1ChooseYourself'}>
                            <span onClick={handleDaytimeClick}>В течение дня</span>
                        </div>
                        <div className={!isDaytime ? 'ofcButton2DuringTheDay' : 'ofcButton2ChooseYourself'}>
                            <span onClick={handleChooseTimeClick}>выбрать самому</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function OfcCommentDateOk() {
        return (
            <>
                <div className={!isDaytime ? 'ofcLabelWrapper1' : 'ofcLabelWrapper2'}>
                    <label>
                        <input
                            type="text"
                            onChange={handleCommentInput}
                            value={comment}
                            placeholder={"Комментарий к доставке"}
                            className={"ofcLabelInput"}
                        />
                    </label>
                </div>
                <div className="ofcDeliverySummaryWrapper">
                    <div className="ofcDeliverySummary">
                        {selectedTime ? (
                            isDaytime ? (
                                <span>
                                {deliveryDay} {ChooseMonthFromDate(deliveryMonth)}, в течение дня
                            </span>
                            ) : (
                                <span>
                                {deliveryDay} {ChooseMonthFromDate(deliveryMonth)}, с {deliveryTimeFrom} до {deliveryTimeTo}
                            </span>
                            )
                        ) : (
                            <span>Выбрать время доставки</span>
                        )}
                    </div>
                    <div className={"ofcOkButton"} onClick={handleOkButtonClick}>
                        <span>Ок</span>
                    </div>
                </div>
            </>
        );
    }

    function OrderFormationCalendarModal(props:any) {
        return (
            <>
                <div className={showModal ? 'ofcCloseAreaOpen' : 'ofcCloseAreaClose'} onClick={toggleModal}/>
                <div className={showModal ? 'ofcModalWrapperOpen' : 'ofcModalWrapperClose'}>
                    <div className={"ofcModalWindow"}>
                        <div className={"ofcHeader"}>
                            <span>Когда к вам приехать?</span>
                            <div id="ofcCloseButton" onClick={toggleModal}>
                                <img src="/Pictures/Закрыть_календарь.svg"/>
                            </div>
                        </div>
                        <div className="calendar">
                            <Calendar Date={props.Date} onDateSelect={handleDateSelect}/>
                        </div>
                        <div className="ofcLineAfterCalendar">
                            <img src="/Pictures/Rectangle 1116.svg"/>
                        </div>
                        {OfcTwoDropdowns()}
                        {OfcCommentDateOk()}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div>
            <div className={"order-choose-time-button"} onClick={toggleModal} style={selectedTime?{boxShadow:"0px 0px 5px #01ABA2"}: {}}>
                <span id={"order-choose-time-text"}>
                {selectedTime?
                    (
                        !isDaytime ? (
                                `${deliveryDay} ${ChooseMonthFromDate(deliveryMonth)}, с ${deliveryTimeFrom} до ${deliveryTimeTo}`
                        ) :
                            (
                                `${deliveryDay} ${ChooseMonthFromDate(deliveryMonth)}, в течение дня`
                            )
                    )
                    :
                    (
                            "Выбрать время доставки"
                    )
                }
                </span>
            </div>
            {OrderFormationCalendarModal({Date:initialFrom})}
        </div>
    );
}