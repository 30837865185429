import React, {useEffect, useState} from "react";
import "./OrderItems/Order.css";
import DropdownLocation from "./DropdownLocation/DropdownLocation";
import {ICard, ICardChanged, ICardObject} from "../../../../models/product/productCard";
import {ISupplierAdress} from "../../../../models/supplier/supplier";
import OrderFormationCalendar from "./OrderFormationCalendar/OrderFormationCalendar";
import ProfileAdress from "../Profile/ProfileAdress";
import {useNavigate} from "react-router-dom";

interface Interface {
	cards: ICardChanged[] | undefined;
	name: string | undefined;
	delivery: boolean;
	orderId: string;
	initialComment: string;
	addressId: string | undefined;
	adresses?: ISupplierAdress[];
	SetAddress: (orderId: string, address: ISupplierAdress) => void;
	SetTime: (orderId: string | undefined, from: string, until: string) => void;
	SetComment: (invoiceId: string | undefined, comment: string) => void;
	initialFrom: string;
	initialTo: string;
	selectedTime: boolean;
}

function DropdownOrder({
	                       cards,
	                       name,
						   orderId,
	                       initialComment,
	                       delivery,
	                       adresses,
	                       addressId,
	                       SetAddress,
	                       SetTime,
	                       SetComment,
	                       initialFrom,
	                       initialTo,
	                       selectedTime
                       }: Interface) {
// function DropdownOrder({cards,adresses}:Interface){
	const [details, setDetails] = useState(false)
	const [pickAdress, setPickAdress] = useState(false)
	const [dropDownAdresses, setDropDownAdresses] = useState(adresses)
	const navigate = useNavigate()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	
	function price(item: ICardChanged) {
		return new Intl.NumberFormat("ru",
			{style: "currency", currency: "RUB"}).format(Number(item.isCheap? item.price2 : item.price1))
	}
	
	const adressAddHanlder = (id: string, streetandNumber: string) => {
		setPickAdress(false)
		setDropDownAdresses((prevState) => {
			let newState = prevState
			if (newState) {
				newState.push({id: id, streetandNumber: streetandNumber, destinationOption: 2})
				console.log('newState', newState)
			}
			return newState
		})
	}
	const adressDeleteHanlder = () => {
		setPickAdress(false)
	}
	//вывод списка выбранных товаров
	let CardsLength = 0;
	if (cards != undefined) {
		CardsLength = cards?.length;
	}
	const positionsTitle = CardsLength + " Позиции(й)";
	
	const ListDropdown: any = () => {
		var list = [];
		// for (var key in cards) {
		for (let i = 0; i < CardsLength; i++) {
			list.push(
				<tr>
					<td className={"first-stylized-column"}>·</td>
					<td className={"second-stylized-column"}>{cards && cards[i].name_of_product}</td>
					<td className={"third-stylized-column"}>{cards && cards[i].orderAmount} шт.</td>
					<td className={"fourth-stylized-column"}>{cards && price(cards[i])}</td>
				</tr>
			)
		}
		return (list)
	};
	return (
		<>
			<div className="order-dropdown-wrapper">
				<span id={"order-text"}>Адрес доставки:</span>
				<DropdownLocation
					arr={dropDownAdresses}
					addressId={addressId ? addressId : ""}
					delivery={delivery}
					SetAddress={SetAddress}
					orderId={orderId}
					setPickAdress={setPickAdress}
				/>
			</div>

			<div className="order-dropdown-wrapper">
				<span id={"order-text"}>Время доставки:</span>
				<OrderFormationCalendar
					SetTime={SetTime}
					SetComment={SetComment}
					name={name}
					initialComment={initialComment}
					initialFrom={initialFrom}
					initialTo={initialTo}
					orderId={orderId}
					selectedTime={selectedTime}
				/>
			</div>

			<div className="order-positions">
				<div className={"order-header-item-title"}>Кол-во позиций:</div>
				<div className={"order-header-button-wrapper"} onClick={() => (setDetails(!details))}>
					<div className={"order-header-item-positions"}>{positionsTitle}</div>
					<img style={details ? {transform: "scale(1,-1)"} : {}} className={"order-header-button"}
						 src={"/Pictures/Vector 8red.svg"}/>
				</div>
			</div>
			{details && <div className="order-main-wrapper">
				<table><ListDropdown/></table>
			</ div>}
			{pickAdress && delivery && <>
				<div style={{height: "20px"}}></div>
				<ProfileAdress adress={{}} index={1000000} notFromProfile={true} adressAddHandler={adressAddHanlder}
							   adressDeleteHandler={adressDeleteHanlder}/>

			</>}
		</>

	)
}

export default DropdownOrder