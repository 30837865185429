import React from 'react';
import './OrderStatus.css';

interface StatusData {
    status: number;
    statusName: string;
    date: string;
}

interface OrderStatusProps {
    historyStatuses: StatusData[];
    deliveryType: 'delivery' | 'pickup';
    isPayed: boolean;
    payedDate?: string; // Дата оплаты, если заказ оплачен
}

const styleLogic = (
    statuses: StatusData[],
    deliveryType?: 'delivery' | 'pickup',
    isPayed?: boolean,
    payedDate?: string
) => {
    const baseOptions = {
        delivery: [
            {status: 1, title: 'Открыто', picture: '/Pictures/VectorOpenOrder.svg'},
            {status: 2, title: 'Принято', picture: '/Pictures/VectorAccepted.svg'},
            {status: 4, title: 'Готов к отправке', picture: '/Pictures/VectorReadyToGet.svg'},
            {status: 5, title: 'Доставка', picture: '/Pictures/Доставка.svg'},
            {status: 6, title: 'Выдан', picture: '/Pictures/Выдан.svg'},
            {status: 7, title: 'Оплачен', picture: '/Pictures/VectorPayedOrder.svg'},
        ],
        pickup: [
            {status: 1, title: 'Открыто', picture: '/Pictures/VectorOpenOrder.svg'},
            {status: 2, title: 'Принято', picture: '/Pictures/VectorAccepted.svg'},
            {status: 3, title: 'Готов к выдаче', picture: '/Pictures/VectorReadyToGet.svg'},
            {status: 6, title: 'Выдан', picture: '/Pictures/Выдан.svg'},
            {status: 7, title: 'Оплачен', picture: '/Pictures/VectorPayedOrder.svg'},
        ],
    };

    let baseStatuses = statuses??[]

    const indexCanceledBuyer = baseStatuses?.findIndex((s) => s?.status === 8);

    if(indexCanceledBuyer && baseStatuses[indexCanceledBuyer + 1] && baseStatuses[indexCanceledBuyer + 1]?.status != 10) {
        let newBaseStatuses = []
        newBaseStatuses.push(statuses[1]);
        newBaseStatuses.push(...baseStatuses.slice(indexCanceledBuyer + 1, baseStatuses.length));
        baseStatuses = newBaseStatuses;
    }


    const extraOptions = {
        canceledBuyer: {status: 8, title: 'Ожидание отмены', picture: '/Pictures/CloseWhite.svg', style: 'order-status-red'},
        canceled: {status: 9, title: 'Отменен', picture: '/Pictures/CloseWhite.svg', style: 'order-status-red'},
    };

    const activeStatuses = deliveryType === 'delivery' ? baseOptions.delivery : baseOptions.pickup;

    const displayStatuses = activeStatuses.map((option) => {
        const matchingStatus = baseStatuses?.find((s) => s.status === option.status);
        return matchingStatus
            ? {...option, time: new Date(matchingStatus.date).toLocaleString('ru-RU'), style: 'order-status-green'}
            : {...option, style: 'order-status-grey'};
    });

    // Обновляем статус "Оплачен" в `displayStatuses`, если заказ оплачен
    const payedStatusIndex = displayStatuses.findIndex((option) => option.status === 7);
    if (payedStatusIndex !== -1) {
        displayStatuses[payedStatusIndex] = isPayed && payedDate
            ? {
                ...displayStatuses[payedStatusIndex],
                time: new Date(payedDate).toLocaleString('ru-RU'),
                style: 'order-status-green',
            }
            : displayStatuses[payedStatusIndex];
    }

    // Определяем последний "нормальный" статус
    const lastNormalStatusIndex = displayStatuses.reduce(
        (lastIndex, item, index) => (item.style === 'order-status-green' ? index : lastIndex),
        -1
    );

    // Проверяем наличие статусов отмены
    const canceledStatus = baseStatuses?.find((s) => s.status === extraOptions.canceled.status);
    const canceledBuyerStatus = baseStatuses?.find((s) => s.status === extraOptions.canceledBuyer.status);

    // Добавляем статус отмены после последнего "нормального" статуса
    if (canceledStatus) {
        displayStatuses.splice(lastNormalStatusIndex + 1, 0, {
            ...extraOptions.canceled,
            time: new Date(canceledStatus.date).toLocaleString('ru-RU'),
        });
    } else if (canceledBuyerStatus) {
        displayStatuses.splice(lastNormalStatusIndex + 1, 0, {
            ...extraOptions.canceledBuyer,
            time: new Date(canceledBuyerStatus.date).toLocaleString('ru-RU'),
        });
    }

    return displayStatuses;
};

const OrderStatus: React.FC<OrderStatusProps> = ({historyStatuses, deliveryType, isPayed, payedDate}) => {
    const StatusOptions = styleLogic(historyStatuses, deliveryType, isPayed, payedDate);

    return (
        <div className="order-status-list">
            {StatusOptions.map((item: any, index) => (
                <div key={index} className="order-status-item">
                    <div className={`order-status-logo ${item.style}`}>
                        <img className="order-status-logo-picture" src={item.picture} alt={item.title}/>
                    </div>
                    <div className="order-status-content">
                        <span className="order-status-title">{item.title}</span>
                        {item.time && <span className="order-status-time">{item.time}</span>}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OrderStatus;
