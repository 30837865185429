import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {useContext, useRef} from "react";
import {Context} from "../../../index";
import "./Dialog.css";

interface IDialogComponent {
    open: boolean;
    text: string;
    options?: boolean;
    title?: string;
}

export default function Dialog(props: IDialogComponent) {
    const {store} = useContext(Context);
    const ref = useRef(null);

    const CloseCancelHandler = () => (
        props.options ? store.setDialogResult(false) : store.setDialogClose()
    );

    useOnClickOutside(ref, () => CloseCancelHandler());

    return (
        <>
            {props.open && (
                <div className="dialog_wrapper active">
                    <div className="dialog_container" ref={ref}>
                        <button className="dialog_close" onClick={CloseCancelHandler}>
                            <img src="/Pictures/Close.svg" alt="close"/>
                        </button>


                        <div className="dialog_content">
                            {props.title && <div className="dialog_title">{props.title}</div>}
                            <div className="dialog_text">{props.text}</div>
                        </div>

                        {props.options ? (
                                <div className="dialog_options">
                                    <div className="dialog_line"></div>
                                    <button className="dialog_option_yes" onClick={() => store.setDialogResult(true)}>
                                        Да
                                    </button>
                                    <div className="dialog_option_divider"></div>
                                    <button className="dialog_option_no" onClick={CloseCancelHandler}>
                                        Нет
                                    </button>
                                </div>
                            )
                            :
                            <div className="dialog_options">
                                <div className="dialog_line"></div>
                                <button className="dialog_option_no" onClick={CloseCancelHandler}>
                                    Ок
                                </button>
                            </div>
                        }

                    </div>
                </div>
            )}
        </>
    );
}
