import React, {useContext, useEffect, useState} from "react";
import "./MyOrdersActive.css";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import MyOrdersElement from "../MyOrdersElement/MyOrdersElement";
import {IMyOrders} from "../../../../../models/models";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {Link} from "react-router-dom";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {Context} from "../../../../../index";
import CartEmpty from "../../../../customUI/NoProductsPage/NoProductsPage";

function MyOrdersActive() {
    const {store} = useContext(Context);
    const [dataArray,setDataArray] = useState<IMyOrders[]>([])
    const [numberOfOrders , setNumberOfOrders] = useState<number>(0)
    const [numberOfUnfinishedOrders , setNumberOfUnfinishedOrders] = useState<number>(0)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        async function GetDataMyOrders() {
            const MyOrders = await  CabinetBuyerService.getMyOrders(true)
            await setDataArray(MyOrders.data);
            await setNumberOfOrders(MyOrders.data.length);
            const InfoContinueOrder = await CabinetBuyerService.getInfoContinueOrder()
            await setNumberOfUnfinishedOrders(InfoContinueOrder.data);
        }

        store.DataLoadingON()
        GetDataMyOrders()
            .catch(()=> {
                store.ErrorON()
            })
            .finally(()=> {
            store.DataLoadingOFF()
        })
    }, [])

    const continueButton =
        <Link to={SpaRoutes.ORDER_FORMATION}>
                <div className="myorders-continue-button">
                Продолжить оформление заказа
                    <div className="myorders-continue-number">{numberOfUnfinishedOrders}
                    <img className={"myorders-number-ellipse"} src={"/Pictures/Ellipse 87.svg"}/>
                </div>
            </div>
        </Link>;

    const ShowList = dataArray.map((item:IMyOrders) => (
        <div key={`myordact${Math.random()}`} className={"myorders-order-element"}>
            <MyOrdersElement props={item}/>
        </div>
    ))
    return(
        <>
            <CustomNavigateHeader
                title={`Заказы`}
                trashClickHandler={()=>null}
                withTrash={false}
            />
            {(!store.isError && !store.isDataLoading )&&
                <>
                    {numberOfUnfinishedOrders !=0 && continueButton}
                    {(numberOfOrders === 0)?(
                        <CartEmpty
                            tittle={"Нет активных заказов"}
                            text={"У вас пока еще нет ни одного активного заказа. Вы можете перейти в корзину и оформить новый заказ, или перейти в каталог"}
                            link1={SpaRoutes.CART}
                            link1text={"В корзину"}
                            link2={SpaRoutes.MY_ORDERS_ACTIVE}
                            link2text={"В каталог"}
                        />                    ):(
                        <>
                            <h4 className={"myorders-title"}>активные заказы</h4>
                            <div className={"myorders-container"}>
                                {ShowList}
                            </div>
                        </>
                    )}
                </>
            }
        </>
    )
}export default MyOrdersActive