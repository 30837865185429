import {useNavigate, useParams} from "react-router-dom";
import "../../../Customer/MyOrders/MyOrderPayment/MyOrderPayment.css";
import React, {useCallback, useContext, useEffect, useState} from "react";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import Checkbox01 from "../../../../customUI/checkbox01/checkbox01";
import FunctionButton from "../../../../customUI/FunctionButton/FunctionButton";
import {MyOrderPaymentModal} from "../../../Customer/MyOrders/MyOrderPayment/MyOrderPaymentModal/MyOrderPaymentModal";
import {ModalContext} from "../../../../../context/ModalContext";
import {Loader} from "../../../../customUI/Loader/Loader";
import {Context} from "../../../../../index";
import CabinetSellerService from "../../../../../services/CabinetSellerService";


export default function SellerOrderPaymentOption(){

    const {store} = useContext(Context);

    const [openModal,setOpenModal] = useState(false);
    const [openModalFail,setOpenModalFail] = useState(false);
    const[loading,setLoading] = useState(true);
    const[paymentStatus,setPaymentStatus] = useState<number>(0);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const {modal, open, close} = useContext(ModalContext);
    const navigate = useNavigate();
    const params = useParams();
    const InvoiceId = params.id || "0";
    const price = params.price || "0";
    const payed = params.payed || "false";
    const chosenOption = Number(params.paymentOption) || 0;

    useEffect(()=>{
        setPaymentStatus(chosenOption);
    },[])

    const postApprovePayment = async () => {
        setLoading(false);
        CabinetSellerService.approvePaymentOfOrder(InvoiceId)
            .then((response)=>{
                if(response.status===200){
                    setLoading(true)
                    setOpenModal(true)
                }
                else{
                    setLoading(true);
                    setOpenModalFail(true);
                }
            })
            .finally(()=>{
                setLoading(true);
            });
    };

    const PaymentOptions:{name:string;id:number;}[] =
        [{name:"Наличные",id:1},
            {name:"Банковская карта",id:2},
            {name:"Бонусы",id:3},
            {name:"Со счета",id:4},
            {name:"QR-код",id:5},
            {name:"По терминалу",id:6}];

    const CloseModalHandler = () => {
        setOpenModal(false);
        setOpenModalFail(false);
        close();
        navigate(-1);
    }

    const PaymentOptionsList = useCallback((paymentStatus:number)=>{
        return PaymentOptions.map((payment_option) => {
            if (payment_option.id === paymentStatus) return (
                <div key={`щквpayopt2${Math.random()}`} className={"payment-option"}>
                    <Checkbox01 checked={true} changeHandler={() => {}}/>
                    <span className={"payment-option-description"}>{payment_option.name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>
            )
            else return (
                <div key={`щквpayopt1${Math.random()}`} className={"payment-option"}>
                    <Checkbox01 checked={false} changeHandler={() => {}}/>
                    <span className={"payment-option-description"}>{payment_option.name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>)
        });
    },[[],paymentStatus])


    return(
        <>
            {!loading?(
                    <Loader load={true} />):
                (<>
                    <CustomNavigateHeader
                        title={`Заказ №${InvoiceId}`}
                        trashClickHandler={()=>null}
                        withTrash={false}
                    />
                    <p id={"myorder-payment-upper-title"}>Оплата</p>
                    <span id={"myorder-payment-title"}>Выбранный косметологом способ оплаты</span>
                    <div id="myorder-payment-status-list">{PaymentOptionsList(paymentStatus)}</div>
                    <div id="myorder-payment-confirm">
                        {(payed === "true") ?
                            <span style={{fontSize: 13, fontWeight: 700, lineHeight:"16px"}}>Оплата подтверждена</span>
                            : <FunctionButton text={"Подтвердить оплату косметологом"} onClickHandler={postApprovePayment} style={{width: "min-content",whiteSpace: "nowrap", fontSize: 13, fontWeight: 700, lineHeight:"16px", cursor: "pointer"}}/>}
                    </div>
                    {openModal && <MyOrderPaymentModal
                        title={"Подтверждение оплаты"}
                        text={"Оплата подтверждена успешно!"}
                        onClose={CloseModalHandler}>
                    </MyOrderPaymentModal>}
                    {openModalFail && <MyOrderPaymentModal
                        title={"Подтверждение оплаты"}
                        text={"Произошла ошибка! Не удалось подтвердить оплату"}
                        onClose={CloseModalHandler}>
                    </MyOrderPaymentModal>}
                </>)}
        </>
    )
}