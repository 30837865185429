//Замена обратных слешей на прямые слэшы
import { IDropdownOption } from '../models/models'
import ChatService from '../services/ChatService'
import { IFilterBoxGroups } from '../components/Supplier_UI_kit/Filter4Magazines/SelectFilterBox'
import React from 'react'

export function strReplace(str?: string) {
	if (str)
		return str.replaceAll('\\', '/')
}

//функция форматирования числа в денежный формат в рублях
export function Ru(item: number) {
	return new Intl.NumberFormat('ru', { style: 'currency', currency: 'RUB' }).format(item)
}

export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
	return o[propertyName] // o[propertyName] is of type T[K]
}

//функция для проверки наличия конкретного товара в корзине
/*export const findProduct = (productId:string, orderId:string, orderAmount:number )=>{
    let counter = 0;
    CabinetBuyerService.basket().then((data)=>{
        for (const key in data.data.suppliersitems){
            for (const key2 in data.data.suppliersitems[key].suppliercards){
                if (data.data.suppliersitems[key].suppliercards[key2].productId === productId){
                    counter++;
                    orderId = data.data.suppliersitems[key].suppliercards[key2].orderId;
                    orderAmount = data.data.suppliersitems[key].suppliercards[key2].orderAmount;
                    return CabinetBuyerService.change_amount(orderId,orderAmount + 1);
                }
            }
        }
        if(counter === 0) return CabinetBuyerService.basket_insert(productId);
    })
}*/
export function randomizeDefaultProfileImgPath(name: string = '2') {
	return name.length % 2 == 0 ? '/Pictures/DefaultAvatar1.svg' : '/Pictures/DefaultAvatar2.svg'
}

export async function sendMessageAsync(
	FromUserId: string,
	FromUserName: string,
	Message: string,
	WhomId: string,
	type: string = '1',
	infoId: string = '0'
) {
	let chatMessageId = ''
	await ChatService.chatSaveMessage({
		from: FromUserId,
		to: WhomId,
		message: Message,
		infoId: infoId,
		type: Number(type)
	}).then((response) => {
		chatMessageId = response.data
	})
	if (type === '5') {
		ChatService.sendMessages({
			fromUserId: FromUserId,
			toUserId: 'group' + infoId,
			fromName: FromUserName,
			message: Message,
			eventId: 'group' + infoId,
			messageType: 5
		})
		ChatService.updateCounter('group', 'group' + infoId)
	} else {
		ChatService.sendMessages({
			fromUserId: FromUserId,
			toUserId: WhomId,
			fromName: FromUserName,
			message: Message
		})
		ChatService.updateCounter(WhomId, FromUserId)
	}
	console.log('the received new id is: ', chatMessageId)
	return chatMessageId
	
}

export function getParsedDate(dateString: string, withoutTime: boolean, onlyTime: boolean, reversed?: boolean): string {
	if (!dateString)
		return ''
	const [date, time] = dateString.split('T') // Разбиваем дату и время
	const [year, month, day] = date.split('-') // Разбиваем дату на компоненты
	const [hours, minutes] = time.split(':') // Разбиваем время на компоненты
	
	if (onlyTime) {
		return `${hours}:${minutes}`
	} else if (reversed) {
		return `${year}.${month}.${day}`
	} else return (withoutTime ? `${day}.${month}.${year}`
		: `${day}.${month}.${year}, ${hours}:${minutes}`)
}

export function removeGorod(input: string): string {
	if (input) {
		return input.replaceAll(' город', '').replaceAll(' Город', '')
	}
	return input
}

export function createFilterGroupsForMagazines(mags: any): any {
	let list: IFilterBoxGroups[] = []
	for (const magazine of mags) {
		let found = false
		for (const item of list) {
			if (item.title === removeGorod(magazine.city)) {
				item.filterbox.filterButtons.push(
					{
						button: <>
							<div className={'FilterText'}>{magazine.isVirtual ?
								removeGorod(`Виртуальный для ${magazine.virtualRegion} (${magazine.streetAndNumber})`) :
								removeGorod(`г.${magazine.city}, ${magazine.streetAndNumber}`)}</div>
						</>,
						value: magazine.dropAdressId ? magazine.dropAdressId : ''
					}
				)
				found = true
				break
			}
		}
		if (!found) {
			list.push(
				{
					title: removeGorod(magazine.city),
					filterbox: {
						filterButtons: [{
							button: <>
								<div className={'FilterText'}>{magazine.streetAndNumber}</div>
							</>,
							value: magazine.dropAdressId ? magazine.dropAdressId : ''
						}],
						updateFilter: () => {},
						filterList: []
					}
				}
			)
		}
	}
	return list
}

export function getParsedDateMonthAndYear(dateString: string): number[] {
	if (!dateString || dateString === '')
		return [0, 0]
	const [date, time] = dateString.split('T') // Разбиваем дату и время
	const [year, month, day] = date.split('-') // Разбиваем дату на компоненты
	
	return [Number(month), Number(year)]
}

export function CompareDates(dateStrings: string[]): string {
	let latestDateString = dateStrings[0]
	for (let i = 1; i < dateStrings.length; i++) {
		const currentDate = new Date(dateStrings[i])
		const latestDate = new Date(latestDateString)
		if (currentDate > latestDate) {
			latestDateString = dateStrings[i]
		}
	}
	return latestDateString
}

export function GetDateForOrder(dateStrings: string[]): Date {
	let latestDateDate = new Date(dateStrings[dateStrings.length - 1])
	return latestDateDate
}

export function DifferenceDates(dateOneStrings: string, dateTwoString: string): string {
	const first = new Date(dateOneStrings)
	const second = new Date(dateTwoString)
	let diffs = Math.abs(Math.round((first.getTime() - second.getTime()) / 60000))
	let hours = Math.floor(diffs / 60)
	let minutes = Math.floor(diffs - hours * 60)
	return hours + 'ч. ' + minutes + 'м.'
}

//Функция для удаления повторяющихся объектов в массиве типа IDropdownOption
export function removeDuplicates(arr: IDropdownOption[]) {
	const uniqueArray: IDropdownOption[] = []
	const keys: string[] = []
	
	arr.forEach((obj: IDropdownOption) => {
		const key: string = JSON.stringify(obj)
		if (!keys.includes(key)) {
			keys.push(key)
			uniqueArray.push(obj)
		}
	})
	return uniqueArray
}

interface Data {
	topCategoryName: string;
	topCategoryId: string;
}

export function removeDuplicates2(arr: Data[]): Data[] {
	const seenIds: Set<string> = new Set()
	const uniqueArr: Data[] = []
	
	for (let i = 0; i < arr.length; i++) {
		const currentObject = arr[i]
		
		if (!seenIds.has(currentObject.topCategoryId)) {
			seenIds.add(currentObject.topCategoryId)
			uniqueArr.push(currentObject)
		}
	}
	
	return uniqueArr
}

export function countDaysFromNowToPastDate(pastDate: string) {
	const dateNow = new Date()
	const datePast = new Date(pastDate)
	
	const timeDifference = dateNow.getTime() - datePast.getTime()
	const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24))
	
	if (daysDifference > 30) {
		const monthsDifference = Math.floor(daysDifference / 30)
		if (monthsDifference > 12) {
			const yearsDifference = Math.floor(monthsDifference / 12)
			return `${yearsDifference} год(а/лет) назад`
		}
		return `${monthsDifference} месяц(ев/а) назад`
	}
	return `${daysDifference} день(дней) назад`
}

export function dateTransformToCSharpFormat(datum: Date) {
	let subtrackt = 0
	let timeIndi = 'AM'
	if (datum.getHours() > 11) {
		subtrackt = 12
		timeIndi = 'PM'
	}
	return (datum.getDate() + '/' + datum.getMonth() + 1) + '/' + datum.getFullYear() + ' '
		+ (datum.getHours() - subtrackt) + ':' + datum.getMinutes() + ':' + datum.getSeconds() + ' ' + timeIndi
}

export function dateTransformForInput(datum: Date) {
	let lead1 = ''
	let lead2 = ''
	if (datum.getMonth() < 9) {
		lead1 = '0'
	}
	if (datum.getDate() < 10) {
		lead2 = '0'
	}
	return datum.getFullYear() + '-' + lead1 + (datum.getMonth() + 1) + '-' + lead2 + datum.getDate()
}

export function dateTransformTtoDate(datum: string) {
	let Datum = datum.split('T')[0]
	let Year = Datum.split('-')[0]
	let Month = Datum.split('-')[1]
	let Day = Datum.split('-')[2]
	return Day + '.' + Month + '.' + Year.slice(2)
}

export function dateTransformForChat(datum: string) {
	
	let DateTime = datum.split(' ')[1]
	let indicator = datum.split(' ')[2]
	let zero1 = ''
	if (DateTime.split(':')[1].length < 2) {
		zero1 = '0'
	}
	let zero2 = ''
	if (indicator === 'PM') {
		let hours = Number(DateTime.split(':')[0]) + 12
		
		return hours.toString() + ':' + zero1 + DateTime.split(':')[1]
	}
	if (DateTime.split(':')[0].length < 2) {
		zero2 = '0'
	}
	return zero2 + DateTime.split(':')[0] + ':' + zero1 + DateTime.split(':')[1]
}

export function calculateDateDifference(date1: string, date2: string) {
	const diffInMilliseconds = Math.abs(Number(new Date(date1)) - Number(new Date(date2)))
	const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
	const diffInMinutes = Math.floor(diffInSeconds / 60)
	const diffInHours = Math.floor(diffInMinutes / 60)
	const diffInDays = Math.floor(diffInHours / 24)
	const diffInMonths = Math.floor(diffInDays / 30)
	const diffInYears = Math.floor(diffInMonths / 12)
	
	if (diffInYears >= 40) {
		return '-'
	} else if (diffInYears >= 1) {
		return diffInYears + ' г'
	} else if (diffInMonths >= 1) {
		return diffInMonths + ' мес'
	} else if (diffInDays >= 1) {
		return diffInDays + ' дн'
	} else if (diffInHours >= 1) {
		return diffInHours + ' ч'
	} else {
		return diffInMinutes + ' мин'
	}
}
