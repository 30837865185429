import React, {useContext, useEffect, useRef, useState} from "react"
import "./ProductDetails.css"

import {
    GalleryImage,
    IDropdownOption,
    IFilter,
    IMagazines,
    IRootProductQuestion,
    ISubCategories,
    ISubCategory,
    ISupplierEditProfile,
    ITopCategories,
    ITopCategory,
} from "../../../../models/models"

import "react-datepicker/dist/react-datepicker.css"
import {Context} from "../../../../index"
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown"
import CustomInput from "../../../customUI/CustomInput/CustomInput"

import {useNavigate, useParams} from "react-router-dom"

import HomeService from "../../../../services/HomeService"
import {IPreviewCard} from "../../../../models/product/productCard"
import CabinetSellerService from "../../../../services/CabinetSellerService"
import ProductCard_1 from "../../../Supplier_UI_kit/ProductCard_1/ProductCard_1"
import FilterDropDowns from "../../../customUI/CustomDropdown/FilterDropDowns"
import {removeGorod} from "../../../../functions/functions"
import {CustomIMG} from "../../../customUI/CustomIMG/CustomIMG"
import ProductCard from "../../../customUI/ProductCard/ProductCard";
import LoadProductImage from "../../../customUI/LoadProductImage/LoadProductImage";

const ProductDetails = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [productData, setProductData] = useState<IRootProductQuestion>({})
    const [userData, setUserData] = useState<ISupplierEditProfile>({})
    const [loadedProductData, setLoadedProductData] = useState(false)
    const [oldProductData, setOldProductData] = useState<IRootProductQuestion>({})
    const [status, setStatus] = useState(true)
    const [loadedTopCategories, setLoadedTopCategories] = useState(false)
    const [loadedMagazines, setLoadedMagazines] = useState(false)
    const [loadedAll, setLoadedAll] = useState(false)
    const [isVirtual, setIsVirtual] = useState(false)
    const [amountEditable, setAmountEditable] = useState(true)

    const [topCategoryName, setTopCategoryName] = useState("Категория")
    const [subCategoryName, setSubCategoryName] = useState("Подкатегория")
    const [topCategories, setTopCategories] = useState<ITopCategory[]>([])
    const [subCategories, setSubCategories] = useState<ISubCategory[]>([])
    const [magazineName, setMagazineName] = useState("Выберите склад")
    const [magazines, setMagazines] = useState<IMagazines[]>([])
    const [arrayTopCat, setArrayTopCat] = useState<IDropdownOption[]>([])
    const [arraySupCat, setArraySubCat] = useState<IDropdownOption[]>([])
    const [filterArray, setFilterArray] = useState<IFilter[]>([])

    const inputRef = useRef(null)
    const [productFile, setProductFile] = useState<File>()
    const [productPreview, setProductPreview] = useState<string>()

    const [activate, setActivate] = useState(true)
    const [buttonStyle, setButtonStyle] = useState({backgroundColor: "#202020"})

    const [isPattern, setIsPattern] = useState(false)
    const [postRequest, setPostRequest] = useState("")
    const [titlePage, setTitlePage] = useState("")
    const [buttonText, setButtonText] = useState("")
    const [errorPage, setErrorPage] = useState("")
    const [isVisiblePreview, setIsVisiblePreview] = useState("firstLoad")
    const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([])
    const [galleryDeletedImages, setGalleryDeletedImages] = useState<number[]>([])
    const [buttonDeleteStyle, setButtonDeleteStyle] = useState<any>({
        display: "none",
    })

    const product: IPreviewCard = {
        productId: productData["id"],
        image: productData["pictureUrl"],
        name_of_product: productData["title"],
        discount: productData["isCheap"],
        price1: productData["price"],
        price2: productData["discountPrice"],
        amount: productData["amount"],
        supply_company: userData["displayedName"],
        image_supply_company: userData["urlLogo"],
        visibleMaskAmount: productData["visibleMaskAmount"],
        visibleMaskPrice: productData["visibleMaskPrice"],
        isConnected1S: productData.isConnected1S
    }

    let {rootId, productId, questionId} = useParams()
    if (rootId === undefined) rootId = "0"
    if (productId === undefined) productId = "0"
    if (questionId === undefined) questionId = "0"

    const updateSelectedIds = (val: string[]) => {
        setProductData({
            ...productData,
            ["filterAttrIds"]: val,
        })
    }
    useEffect(() => {
        if (!loadedProductData) {
            store.DataLoadingON()
            // для редактирования шаблона
            if (questionId != "0") {
                CabinetSellerService.getRootQuestionInfo({Info1: questionId})
                    .then((response) => {
                        let data = response.data
                        setProductData(data)
                        setOldProductData(data)
                        setProductPreview(response.data["pictureUrl"])
                        response.data.galleryImages&&setGalleryImages(response.data.galleryImages)
                        setIsPattern(true)
                        setTitlePage("Изменить коренной товар")
                        setButtonText("Отправить на модерацию")
                        setPostRequest("editRootQuestion")
                        setLoadedProductData(true)

                        setLoadedMagazines(true)
                    })
                    .catch(() => {
                        store.ErrorON()
                    })
                    .finally(() => {
                        store.DataLoadingOFF()
                    })
            }
            // создание шаблона
            else if (rootId === "0" && productId === "0" && questionId === "0") {
                setIsPattern(true)
                setTitlePage("Создать коренной товар")
                setButtonText("Отправить на модерацию")
                setPostRequest("createRootQuestion")
                setLoadedProductData(true)
                setLoadedMagazines(true)
                setGalleryImages([])
                store.DataLoadingOFF()
            }
            // редактирование уже созданного продукта
            else if (productId != "0") {
                CabinetSellerService.getProductInfo({Info1: productId})
                    .then((response) => {
                        let data = response.data
                        if (data.isVirtual) {
                            setIsVirtual(true)
                        }
                        setProductData(data)
                        setOldProductData(data)
                        setProductPreview(response.data["pictureUrl"])
                        response.data.galleryImages&&setGalleryImages(response.data.galleryImages)
                        setTitlePage("Изменить карточку товара")
                        setButtonText("Сохранить изменения карточки товара")
                        setPostRequest("editProduct")
                        setLoadedProductData(true)
                    })
                    .then(() => {
                        CabinetSellerService.getMagazines().then((response) => {
                            setMagazines(response.data.magazines)
                            setLoadedMagazines(true)
                        })
                    })
                    .catch(() => {
                        store.ErrorON()
                    })
                    .finally(() => {
                        store.DataLoadingOFF()
                    })
            }
            // создание продукта по шаблону
            else if (productId === "0" && rootId && rootId != "0") {
                CabinetSellerService.getRootProductInfo({Info1: rootId})
                    .then((response) => {
                        let data = response.data
                        if (data["amount"] === 0) data = {...data, ["amount"]: undefined}
                        if (data["price"] === 0) data = {...data, ["price"]: undefined}
                        if (data["discountPrice"] === 0)
                            data = {...data, ["discountPrice"]: undefined}
                        setProductData(data)
                        setOldProductData(data)
                        setProductPreview(response.data["pictureUrl"])
                        response.data.galleryImages&&setGalleryImages(response.data.galleryImages)
                        setTitlePage("Создать карточку товара")
                        setButtonText("Создать карточку товара")
                        setPostRequest("createProduct")
                        setLoadedProductData(true)
                    })
                    .then(() => {
                        CabinetSellerService.getMagazinesForCreateProduct(
                            rootId ? rootId : ""
                        ).then((response) => {
                            setMagazines(response.data.magazines)
                            setLoadedMagazines(true)
                        })
                    })
                    .catch(() => {
                        store.ErrorON()
                    })
                    .finally(() => {
                        store.DataLoadingOFF()
                    })
            }
            CabinetSellerService.getProfileData().then((response) => {
                let data = response.data
                setUserData(data)
            })
        }
    }, [])

    useEffect(() => {
        if (!loadedTopCategories) {
            HomeService.getAllCategories()
                .then((response) => {
                    setTopCategories(
                        response.data.topCategories.sort((a: any, b: any) => {
                            if (a.name < b.name) {
                                return -1
                            }
                            if (a.name > b.name) {
                                return 1
                            }
                            return 0
                        })
                    )
                    setLoadedTopCategories(true)
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {
                    store.DataLoadingOFF()
                })
        }
    }, [loadedProductData])

    let ArrayMagazines: IDropdownOption[] = []
    magazines.map((item) => {
        let name = ''
        item.isVirtual ?
            name = removeGorod(`Виртуальный для ${item.virtualRegion} (г.${item.city}, ${item.streetAndNumber})`) :
            name = removeGorod(`г.${item.city}, ${item.streetAndNumber}`)
        ArrayMagazines.push({key: item.dropAdressId, name: name, isDisable: item.isUseForProduct})
    });

    useEffect(() => {
        if (topCategories) {
            let newArrayTopCat: IDropdownOption[] = []
            topCategories.map((item) => {
                newArrayTopCat.push({key: item.topCategoryId, name: item.name})
            })
            setArrayTopCat(newArrayTopCat)
        }
    }, [topCategories])

    useEffect(() => {
        if (subCategories) {
            let newArraySubCat: IDropdownOption[] = []
            subCategories.map((item) => {
                newArraySubCat.push({key: item.categoryId, name: item.name})
            })
            setArraySubCat(newArraySubCat)
        }
    }, [subCategories])

    useEffect(() => {
        let filtarr: IFilter[] = []
        if (topCategories && subCategories) {
            topCategories.map((c) => {
                if (c.topCategoryId === subCategories[0].topCategoryId) {
                    filtarr = filtarr.concat(c.filterAttributes)
                }
            })
            subCategories.map((c) => {
                if (c.categoryId === productData["subCategoryId"]) {
                    filtarr = filtarr.concat(c.filterAttributes)
                }
            })
        }
        let uniqueArray: IFilter[] = []
        filtarr.map((filt) => {
            if (uniqueArray.every((item) => item.attrId !== filt.attrId)) {
                uniqueArray.push(filt)
            }
        })
        setFilterArray(uniqueArray)
    }, [subCategoryName])

    useEffect(() => {
        if (
            loadedProductData &&
            loadedTopCategories &&
            topCategories &&
            subCategories
        ) {
            let subCatId = productData["subCategoryId"]
            if (subCatId != undefined) {
                // don't touch
                const topCat = topCategories.find((item) =>
                    item.categories.find(
                        (item: ISubCategory) => item.categoryId === subCatId
                    )
                )
                const subCat = topCat?.categories.find(
                    (item: ISubCategory) => item.categoryId === subCatId
                )
                if (topCat) {
                    setTopCategoryName(topCat.name)
                    setSubCategoryName(subCat.name)
                    setSubCategories(topCat?.categories)
                }
            }

            setLoadedAll(true)
        }
    }, [loadedProductData, loadedTopCategories, topCategories, subCategories])

    useEffect(() => {
        if (loadedProductData && loadedTopCategories && loadedMagazines) {
            let subCatId = productData["subCategoryId"]
            let magazineId = productData["magazineId"]
            if (subCatId != undefined) {
                // don't touch
                const topCat = topCategories.find((item) =>
                    item.categories.find(
                        (item: { subCatId: string | undefined }) =>
                            item.subCatId === subCatId
                    )
                )
                const subCat = topCat?.categories.find(
                    (item: { subCatId: string | undefined }) => item.subCatId === subCatId
                )
                if (topCat) {
                    setTopCategoryName(topCat.name)
                    setSubCategoryName(subCat.subCatName)
                }
            }

            if (magazineId != undefined) {
                const magazine = magazines.find(
                    (item) => item.dropAdressId === magazineId
                )
                if (magazine != undefined) {
                    let name = ""
                    magazine.isVirtual
                        ? (name = removeGorod(
                            `Виртуальный для ${magazine.virtualRegion} (г.${magazine.city}, ${magazine.streetAndNumber})`
                        ))
                        : (name = removeGorod(
                            `г.${magazine.city}, ${magazine.streetAndNumber}`
                        ))
                    setMagazineName(name)
                }
            }

            setLoadedAll(true)
        }
    }, [loadedProductData, loadedTopCategories, loadedMagazines])

    const onTopCategorySelectChange = (val: string) => {
        const TopCategoryId = val
        const topCategory = topCategories.find(
            (item) => item.topCategoryId === TopCategoryId
        )
        const subCategories = topCategory?.categories
        if (subCategories != null && subCategories.length != 0)
            setSubCategories(subCategories)
        else
            setSubCategories([
                {
                    topCategoryId: "-1",
                    categoryId: "no",
                    name: "Нет подкатегорий",
                    sorter: 0,
                    filterAttributes: [],
                },
            ])
        setSubCategoryName("Подкатегория")
    }

    const onSubCategorySelectChange = (val: string) => {
        setProductData({
            ...productData,
            ["subCategoryId"]: val,
        })
    }

    const onMagazineSelectChange = (val: string) => {
        let mag = magazines.find((mag) => {
            return val === mag.dropAdressId
        })
        if (
            mag &&
            mag.amountEditable !== undefined &&
            mag.amountEditable === false
        ) {
            setAmountEditable(false)
        } else {
            setAmountEditable(true)
        }
        setProductData({
            ...productData,
            ["magazineId"]: val,
        })
    }

    useEffect(() => {
        let visible = isVisiblePreview
        if ((productData["amount"] || productData.visibleMaskAmount) && (productData["price"] || productData["visibleMaskPrice"])) {
            if (visible === "false" || visible === "firstLoad")
                setIsVisiblePreview("true")
        } else if ((!productData["amount"]) || (!productData["price"])) {
            if (visible === "true") setIsVisiblePreview("false")
        }
    }, [productData["amount"], productData["price"], productData.visibleMaskAmount, productData["visibleMaskPrice"]])

    const createFormData = (data: any) => {
        return Object.keys(data).reduce((formData, key) => {
            if(key === "galleryImagesFile"){
                data[key].forEach((item: string | Blob, index: any) => {
                    if(item) formData.append(`${key}[${index}]`, item);
                })
            }
            else{
                formData.append(key, data[key])
            }

            return formData
        }, new FormData())
    }

    const refactorProductData = (data: { [x: string]: any }) => {
        for (const key in data) {
            if (data[key] === null || data[key] === undefined) delete data[key]
        }
        return data
    }

    const deactivateSubmit = () => {
        setActivate(false)
        setButtonStyle({backgroundColor: "rgb(142, 142, 142)"})
    }
    const activateSubmit = () => {
        setActivate(true)
        setButtonStyle({backgroundColor: "#202020"})
    }

    const uploadProduct = (status: number) => {
        console.log(galleryImages.map(item => item.imageFile))
        if (activate) {
            deactivateSubmit()
            if (postRequest === "editRootQuestion") {
                let mydata: any = {
                    id: questionId,
                    title: productData["title"],
                    subCategoryId: productData["subCategoryId"],
                    picture: productFile,
                    galleryImagesFile: galleryImages.map(item => item.imageFile),
                    galleryDeletedImages: galleryDeletedImages.length > 0? galleryDeletedImages : undefined,
                    description: productData["description"],
                    filterAttrIds: productData["filterAttrIds"],
                    status: status,
                }
                const data = createFormData(refactorProductData(mydata))
                CabinetSellerService.updateRootQuestion(data)
                    .then((response) => {
                        activateSubmit()
                        if (response.status === 200) {
                            navigate(-1)
                        }
                    })
                    .catch((error) => {
                        activateSubmit()
                        if (error.response) {
                            if (error.response.status === 500) {
                                //console.log(error.response.message)
                            } else {
                            }
                            // сделать обработчик ошибки
                        }
                    })
            } else if (postRequest === "createRootQuestion") {
                let mydata: any = {
                    title: productData["title"],
                    subCategoryId: productData["subCategoryId"],
                    picture: productFile,
                    description: productData["description"],
                    filterAttrIds: productData["filterAttrIds"],
                    galleryImagesFile: galleryImages.map(item => item.imageFile),
                    galleryDeletedImages: galleryDeletedImages.length > 0? galleryDeletedImages : undefined,
                    status: status,
                }
                const data = createFormData(refactorProductData(mydata))
                CabinetSellerService.addRootQuestion(data)
                    .then((response) => {
                        activateSubmit()

                        if (response.status === 200) {
                            navigate(-1)
                        }
                    })
                    .catch((error) => {
                        activateSubmit()
                        if (error.response) {
                            if (error.response.status === 500) {
                                //console.log(error.response.message)
                            } else {
                            }
                            // сделать обработчик ошибки
                        }
                    })
            } else if (postRequest === "editProduct") {
                let mydata: any = {
                    id: productId,
                    title: productData["title"],
                    amount: productData["amount"],
                    price: productData["price"],
                    discountPrice: productData["discountPrice"],
                    isCheap: productData["isCheap"],
                    description: productData["description"],
                    magazineId: productData["magazineId"],
                    subCategoryId: productData["subCategoryId"],
                    isActivated: productData["isActivated"],
                    onlyDropPoint: productData["onlyDropPoint"],
                    VisibleMaskAmount: productData["visibleMaskAmount"],
                    VisibleMaskPrice: productData["visibleMaskPrice"]
                }
                const data = createFormData(refactorProductData(mydata))
                CabinetSellerService.updateProduct(data)
                    .then((response) => {
                        activateSubmit()
                        if (response.status === 200) {
                            navigate(-1)
                        }
                    })
                    .catch((error) => {
                        activateSubmit()
                        if (error.response) {
                            if (error.response.status === 500) {
                                //console.log(error.response.message)
                            } else {
                            }
                            // сделать обработчик ошибки
                        }
                    })
            } else if (postRequest === "createProduct") {
                // not work
                let mydata: any = {
                    ProductRootId: rootId,
                    MagazineId: productData["magazineId"],
                    Price: productData["price"],
                    Amount: productData["amount"],
                    DiscountPrice: productData["discountPrice"],
                    IsActivated: productData["isActivated"],
                    Discount: productData["isCheap"],
                    OnlyDropPoint: productData["onlyDropPoint"],
                    VisibleMaskAmount: productData["visibleMaskAmount"],
                    VisibleMaskPrice: productData["visibleMaskPrice"]
                }

                CabinetSellerService.addProduct(mydata)
                    .then((response) => {
                        activateSubmit()
                        if (response.status === 200) {
                            navigate(-1)
                        }
                    })
                    .catch((error) => {
                        activateSubmit()
                        alert(error.response.data.message)

                        // сделать обработчик ошибки
                    })
            }
        }
    }

    const checkErrors = (_callback: () => void) => {
        let errors = false
        // тут должен быть обработчик ошибок

        if (!errors) {
            _callback()
        }
    }

    const buttonSave = () => {
        checkErrors(() => {
            uploadProduct(0)
        })
    }
    const buttonSaveWithStatus = (status: number) => {
        checkErrors(() => {
            uploadProduct(status)
        })
    }

    // const buttonDelete = () => {
    //     checkErrors(deleteProduct)
    // }

    // const deleteProduct = () => {
    //     if (window.confirm(productData["deleted"] ? "Восстановить карточку товара?" : "Удалить карточку товара?")) {
    //         let data = {
    //             "productRootId": rootId,
    //             "deleted": !productData["deleted"]
    //         }
    //         store.DataLoadingON()
    //         .postProductRootDelete(data)
    //             .then(response => {
    //                 if (response.status === 200) {
    //                     productData["deleted"] ?
    //                         alert("Карточка товара восстановлена") : alert("Карточка товара удалена")
    //                 }
    //                 navigate(-1)
    //             })
    //             .catch((error) => {
    //                 if (error.response) {
    //                     if (error.response.status === 500) {
    //                         alert("Такой карточки товара нет")
    //                     } else {
    //                         alert("Что-то пошло не так")
    //                     }
    //                 }
    //             })
    //             .finally(() => {
    //                 store.DataLoadingOFF()
    //             })
    //     }
    // }

    const deleteProduct = () => {
        store.showDialog("Вы точно хотите удалить этот товар?", true).then(() => {
            if (store.dialog.dialogResult && productData["id"]) {
                store.DataLoadingON()
                CabinetSellerService.myProductDelete(productData["id"])
                    .then((r) => {
                        if (r.status == 200) {
                            store.showDialog("Товар был удален")
                            navigate(-1)
                        }
                    })
                    .catch(() => {
                        store.showDialog("Что-то пошло не так")
                    })
                    .finally(() => store.DataLoadingOFF())
            }
        })
    }

    const galleryChange = (preview: string | undefined, file: File | undefined, index: number) => {
        setGalleryImages((prevState) => {
            const newState = [...prevState];
            newState[index] = { ...newState[index], imageUrl: preview, imageFile: file};
            return newState;
        });
    }

    const onDeleteGalleryImage = (index: number) => {
        if (window.confirm("Вы точно хотите удалить это изображение?")) {
            let arrDeleted = galleryDeletedImages
            arrDeleted.push(index)
            setGalleryDeletedImages(arrDeleted);

            setGalleryImages((prevState) => {
                return prevState.map((image, i) =>
                    i === index
                        ? { ...image, imageUrl: undefined, imageFile: undefined }
                        : image
                );
            });
        }
    }

    return (
        <>
            {!store.isError && !store.isDataLoading && (
                <>
                    <div className={"productDetails-mainContainer"}>
                        <div className={"productDetails-navigate-container"}>
                            <img
                                className={"productDetails-navigate-image"}
                                onClick={() => navigate(-1)}
                                src={"/Pictures/strelochka.svg"}
                            />
                            <div className={"productDetails-header"}>{titlePage}</div>
                        </div>
                        {isPattern ? (
                            <div className={"productDetails-hint"}>
                                Напишите название на русском и английском языках (при наличии),
                                Например: Volbella, Волбелла
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className={"productDetails-firstContainer"}>
                            <div className={"productDetails-productNames"}>
                                <div className={"productDetails-inputName"}>
                                    <div className={"productDetails-hint-text"}>Название</div>
                                    <CustomInput
                                        name={"name"}
                                        placeHolder={"Название"}
                                        value={productData["title"]}
                                        styleInputField={"productDetails-inputField"}
                                        disabled={!isPattern}
                                        onChange={(event) =>
                                            setProductData({
                                                ...productData,
                                                ["title"]: event.target.value,
                                            })
                                        }
                                        inputSize={"large"}
                                    />
                                </div>

                                <div className={"productDetails-categories-container"}>
                                    <div className={"productDetails-category"}>
                                        <div className={"productDetails-hint-text"}>Категория</div>
                                        <div className={"productDetails-dropDown"}>
                                            <CustomDropdown
                                                styleDropdownDisplay={" sp-dropDown"}
                                                styleDropdownList1={" sp-dropDown-list"}
                                                icon={isPattern}
                                                disabled={!isPattern}
                                                options={arrayTopCat}
                                                dropdownSize={"large"}
                                                value={topCategoryName}
                                                valueSetter={setTopCategoryName}
                                                onChangeHandler={onTopCategorySelectChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={"productDetails-subcategory"}>
                                        <div className={"productDetails-hint-text"}>
                                            Подкатегория
                                        </div>
                                        <div className={"productDetails-dropDown"}>
                                            <CustomDropdown
                                                styleDropdownDisplay={" sp-dropDown"}
                                                styleDropdownList1={" sp-dropDown-list"}
                                                icon={isPattern}
                                                disabled={!isPattern}
                                                dropdownSize={"large"}
                                                options={arraySupCat}
                                                value={subCategoryName}
                                                valueSetter={setSubCategoryName}
                                                onChangeHandler={onSubCategorySelectChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"productDetails-secondContainer"}>
                            <LoadProductImage
                                onChange={(preview, file) => {
                                    setProductPreview(preview)
                                    setProductFile(file)
                                }}
                                disable={!isPattern}
                                previewProps={productPreview}
                                title={"Основное фото"}

                            />


                            <LoadProductImage
                                onChange={(preview, file) => {
                                    galleryChange(preview, file, 0)
                                }}
                                disable={!isPattern}
                                previewProps={galleryImages[0] ? galleryImages[0].imageUrl : undefined}
                                title={"Галлерея"}
                                canDelete={isPattern}
                                onDelete={() => {
                                    onDeleteGalleryImage(0)
                                }}
                            />
                            <LoadProductImage
                                onChange={(preview, file) => {
                                    galleryChange(preview, file, 1)
                                }}
                                disable={!isPattern}
                                previewProps={galleryImages[1] ? galleryImages[1].imageUrl : undefined}
                                canDelete={isPattern}
                                onDelete={() => {
                                    onDeleteGalleryImage(1)
                                }}
                            />
                            <LoadProductImage
                                onChange={(preview, file) => {
                                    galleryChange(preview, file, 2)
                                }}
                                disable={!isPattern}
                                previewProps={galleryImages[2] ? galleryImages[2].imageUrl : undefined}
                                canDelete={isPattern}
                                onDelete={() => {
                                    onDeleteGalleryImage(2)
                                }}
                            />
                            <LoadProductImage
                                onChange={(preview, file) => {
                                    galleryChange(preview, file, 3)
                                }}
                                disable={!isPattern}
                                previewProps={galleryImages[3] ? galleryImages[3].imageUrl : undefined}
                                canDelete={isPattern}
                                onDelete={() => {
                                    onDeleteGalleryImage(3)
                                }}
                            />


                        </div>
                        <div className={"productDetails-description"}>
                            <div className={"productDetails-hint-text"}>Описание</div>
                            <textarea
                                className={"productDetails-description-input"}
                                placeholder={"Описание"}
                                disabled={!isPattern}
                                value={productData["description"]}
                                onChange={(event) =>
                                    setProductData({
                                        ...productData,
                                        ["description"]: event.target.value,
                                    })
                                }
                            />
                        </div>
                        <div
                            className={"productDetails-thirdContainer"}
                            style={{display: isPattern ? "none" : "block"}}
                        >
                            <div className={"productDetails-priceContainer"}>
                                <div
                                    className={"productDetails-priceInputContainer"}
                                    data-visible={productData["visibleMaskAmount"]}
                                >
                                    <div className={"productDetails-hint-text"}>
                                        Количество
                                        <img
                                            className={"productDetails-hint-image"}
                                            src={productData["visibleMaskAmount"] ? "/Pictures/eye.svg" : ""}
                                        />
                                    </div>
                                    {isVirtual || !amountEditable ? (
                                        <div
                                            style={{
                                                fontSize: "12px",
                                                height: "60px",
                                                lineHeight: "normal",
                                            }}
                                        >
                                            Соответствует количеству данного товара на основном складе
                                        </div>
                                    ) : (
                                        <CustomInput
                                            type={"number"}
                                            name={"quantity"}
                                            disabled={isVirtual || !amountEditable}
                                            value={productData["amount"]}
                                            placeHolder={"Количество"}
                                            min={"0"}
                                            styleInputField={"productDetails-inputField"}
                                            onChange={(event) =>
                                                setProductData({
                                                    ...productData,
                                                    ["amount"]: event.target.value,
                                                })
                                            }
                                            inputSize={"large"}
                                        />
                                    )}
                                </div>
                                <div
                                    className={"productDetails-priceInputContainer"}
                                    data-visible={productData["visibleMaskPrice"]}
                                >
                                    <div className={"productDetails-hint-text"}>
                                        Цена за шт.
                                        <img
                                            className={"productDetails-hint-image"}
                                            src={productData["visibleMaskPrice"] ? "/Pictures/eye.svg" : ""}
                                        />
                                    </div>
                                    <CustomInput
                                        type={"number"}
                                        name={"priceUnit"}
                                        value={productData["price"]}
                                        placeHolder={"Цена за шт."}
                                        min={"0"}
                                        styleInputField={"productDetails-inputField"}
                                        onChange={(event) =>
                                            setProductData({
                                                ...productData,
                                                ["price"]: event.target.value,
                                            })
                                        }
                                        inputSize={"large"}
                                    />
                                </div>
                                <div
                                    className={"productDetails-priceInputContainer"}
                                    data-visible={productData["visibleMaskPrice"]}
                                >
                                    <div className={"productDetails-hint-text"}>
                                        Цена со скидкой
                                        <img
                                            className={"productDetails-hint-image"}
                                            src={productData["visibleMaskPrice"] ? "/Pictures/eye.svg" : ""}
                                        />
                                    </div>
                                    <CustomInput
                                        type={"number"}
                                        name={"priceWithDiscount"}
                                        value={productData["discountPrice"]}
                                        placeHolder={"Цена со скидкой"}
                                        min={"0"}
                                        styleInputField={"productDetails-inputField"}
                                        onChange={(event) =>
                                            setProductData({
                                                ...productData,
                                                ["discountPrice"]: event.target.value,
                                            })
                                        }
                                        inputSize={"large"}
                                    />
                                </div>
                                <button
                                    className={"productDetails-button"}
                                    onClick={() =>
                                        setProductData({
                                            ...productData,
                                            ["isCheap"]: !productData["isCheap"],
                                        })
                                    }
                                >
                                    {productData["isCheap"]
                                        ? "Деактивировать скидку"
                                        : "Активировать скидку"}
                                </button>
                            </div>

                            <div className={"productDetails-statusContainer"}>
                                <div className={"productDetails-status"}>
                                    <div className={"productDetails-hint-text"}>Состояние</div>
                                    <div className={"productDetails-status-input"}>
                                        <img
                                            className={"productDetails-status-image"}
                                            src={
                                                productData["isActivated"]
                                                    ? "/Pictures/statusActive.svg"
                                                    : "/Pictures/statusInactive.svg"
                                            }
                                        />
                                    </div>
                                </div>
                                <button
                                    className={"productDetails-button"}
                                    onClick={() =>
                                        setProductData({
                                            ...productData,
                                            ["isActivated"]: !productData["isActivated"],
                                        })
                                    }
                                >
                                    {productData["isActivated"] ? "Отключить" : "Включить"}
                                </button>
                            </div>

                            <div className={"productDetails-storageContainer"}>
                                <div className={"productDetails-hint-text"}>Склад</div>
                                <div className={"productDetails-dropDown"}>
                                    {postRequest === "createProduct" ?
                                        <CustomDropdown
                                            styleDropdownDisplay={"productDetails-dropDown-input"}
                                            icon={true}
                                            options={ArrayMagazines}
                                            dropdownSize={"large"}
                                            value={magazineName}
                                            valueSetter={setMagazineName}
                                            onChangeHandler={onMagazineSelectChange}
                                        />
                                        : <div className={"productDetails-status-input"}>{magazineName}</div>
                                    }
                                </div>
                            </div>

                            <div className={"productDetails-statusContainer"}>
                                <div className={"productDetails-status"}>
                                    <div className={"productDetails-hint-text"}>Доставка</div>
                                    <div className={"productDetails-status-input"}>
                                        <img
                                            className={"productDetails-status-image"}
                                            src={
                                                productData["onlyDropPoint"]
                                                    ? "/Pictures/isDropOnlyFalsePic.svg"
                                                    : "/Pictures/isDropOnlyTruePic.svg"
                                            }
                                        />
                                    </div>
                                </div>
                                <button
                                    className={"productDetails-button"}
                                    onClick={() =>
                                        setProductData({
                                            ...productData,
                                            ["onlyDropPoint"]: !productData["onlyDropPoint"],
                                        })
                                    }
                                >
                                    {productData["onlyDropPoint"]
                                        ? "Включить доставку"
                                        : "Отключить доставку"}
                                </button>
                            </div>

                            <div className={"productDetails-additionContainer"}>
                                <div className={"productDetails-hint-text"}>Дополнительно</div>
                                <div className={"productDetails-addSettings"}>
                                    <div className={"productDetails-addSettings-left"}>
                                        <div className={"productDetails-addSettings-check"}>
                                            <img
                                                src={
                                                    productData.visibleMaskAmount
                                                        ? "/Pictures/checkbox2.svg"
                                                        : "/Pictures/checkbox-empty.png"
                                                }
                                                onClick={() => {
                                                    setProductData({
                                                        ...productData,
                                                        visibleMaskAmount: !productData.visibleMaskAmount
                                                    })
                                                }}
                                            />
                                            <span>Под заказ</span>
                                        </div>
                                        <span>
                                            Выберете этот пункт, чтобы вместо количества товаров
                                            отображалось "Под заказ"
                                        </span>
                                    </div>

                                    <div className={"productDetails-addSettings-right"}>
                                        <div className={"productDetails-addSettings-check"}>
                                            <img
                                                src={
                                                    productData.visibleMaskPrice
                                                        ? "/Pictures/checkbox2.svg"
                                                        : "/Pictures/checkbox-empty.png"
                                                }
                                                onClick={() => {
                                                    setProductData({
                                                        ...productData,
                                                        visibleMaskPrice: !productData.visibleMaskPrice
                                                    })
                                                }}
                                            />
                                            <span>Уточнить цену</span>
                                        </div>
                                        <span>
                                          Выберете этот пункт, чтобы вместо цены отображалось
                                          "Уточнить цену"
                                        </span>
                                    </div>

                                    {/* {
                                <textarea className={"productDetails-description-input"}
                                  disabled={!isPattern}
                                  value={""}
                                 />
                            } */}
                                </div>
                            </div>

                            <div
                                className={"productDetails-previewContainer"}
                                data-visible={isVisiblePreview}
                            >
                                <div className={"productDetails-hint-text"}>Предпросмотр</div>
                                <ProductCard_1 item={product}/>
                            </div>
                        </div>

                        {rootId === "0" &&
                            productId === "0" &&
                            filterArray &&
                            filterArray.length > 0 && (
                                <FilterDropDowns
                                    filters={filterArray}
                                    selectedIds={
                                        productData["filterAttrIds"]
                                            ? productData["filterAttrIds"]
                                            : []
                                    }
                                    updateSelectedIds={updateSelectedIds}
                                />
                            )}
                        <div className={"productDetails-buttons-container"}>
                            {rootId === "0" && productId === "0" ? (
                                <div style={{display: "flex", width: "100%", gap: "10px"}}>
                                    <button
                                        className={"productDetails-buttonSave-2"}
                                        style={buttonStyle}
                                        onClick={() => {
                                            buttonSaveWithStatus(0)
                                        }}
                                    >
                                        {"Сохранить изменения"}
                                    </button>
                                    <button
                                        className={"productDetails-buttonSave-2"}
                                        style={{...buttonStyle, margin: "40px auto"}}
                                        onClick={() => {
                                            buttonSaveWithStatus(1)
                                        }}
                                    >
                                        {buttonText}
                                    </button>
                                </div>
                            ) : (
                                <button
                                    className={"productDetails-supplier-buttonSave"}
                                    style={buttonStyle}
                                    onClick={buttonSave}
                                >
                                    {buttonText}
                                </button>
                            )}
                            {rootId === "0" && productId !== "0" && questionId === "0" && (
                                <button
                                    className={"productDetails-supplier-buttonDelete"}
                                    data-deleted={false}
                                    onClick={() => deleteProduct()}
                                ></button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default ProductDetails
