export enum OrderStatusEnum {
    Hold,             // ждет формирования
    Open,             // открыт
    Accepted,         // принят поставщиком
    ReadyToGive,      // готов к выдаче косметологу
    ReadyToDelivery,  // готов к отправке
    Sending,          // отправлен
    Received,         // выдан косметологу
    Closed,           // закрыт
    CanceledBuyer,
    Canceled          // отменен, если все товары отменены
}

export function getOrderStatusName(status: OrderStatusEnum): string {
    switch (status) {
        case OrderStatusEnum.Hold:
            return "Ждет формирования";
        case OrderStatusEnum.Open:
            return "Открыт";
        case OrderStatusEnum.Accepted:
            return "Взято в работу";
        case OrderStatusEnum.ReadyToGive:
            return "Готов к выдаче";
        case OrderStatusEnum.ReadyToDelivery:
            return "Готов к отправке";
        case OrderStatusEnum.Sending:
            return "Отправлен";
        case OrderStatusEnum.Received:
            return "Выдан";
        case OrderStatusEnum.Closed:
            return "Закрыт";
        case OrderStatusEnum.CanceledBuyer:
            return "Ожидание отмены";
        case OrderStatusEnum.Canceled:
            return "Отменен";
        default:
            return "";
    }
}

export enum OrderProductStatusEnum {
    CanceledBuyer,    // отменен покупателем
    ApproveCanceled,  // одобрена отмена
}

function getOrderProductStatusName(status: OrderProductStatusEnum): string {
    switch (status) {
        case OrderProductStatusEnum.CanceledBuyer:
            return "Отменен покупателем";
        case OrderProductStatusEnum.ApproveCanceled:
            return "Одобрена отмена";
        default:
            return "Неизвестный статус";
    }
}