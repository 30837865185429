import React, {useContext, useEffect, useState} from 'react';
import './Burger.css';
import {Link} from "react-router-dom";

import {CustomIMG} from "../../../../customUI/CustomIMG/CustomIMG";
import {burger_menu} from "../../../../../data/SupplierBurgerMenu/burger_menu";
import {IUser} from "../../../../../models/user/IUser";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {Context} from "../../../../../index";
import {randomizeDefaultProfileImgPath} from "../../../../../functions/functions";

const Burger = () => {
    const {store} = useContext(Context)

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = (name?: string) => {
        setIsOpen(!isOpen);
        if(name === "Каталог товаров"){
            store.FiltersSave("-1","","-1","",[])
        }
    };


    //формирование списка бургера
    let list = [];

    for (let i = 0; i < burger_menu.length; i++) {
        list.push(
            <li className="burgerLi_dealer" key={burger_menu[i].name}>
                <Link className="a-link_burger_dealer" to={burger_menu[i].link} onClick={() => toggleMenu(burger_menu[i].name)}>
                    <div className="img_nameLi_container_dealer">
                        <img src={burger_menu[i].icon}/>
                        {burger_menu[i].name}
                    </div>
                    <img src={"/Pictures/Vector (Stroke).svg"}/>
                </Link>
            </li>
        )
    }

    return (
        <div className='burger-menu'>
            {/*Значок бургера*/}
            <div className='burgerIcon_dealer' onClick={() => toggleMenu()}>
                <span></span>
            </div>
            {/*Содержимое бургера*/}
            <div className={isOpen ? "burgerBackendGrey_dealer" : "burgerBackendTransparent_dealer"} onClick={() => toggleMenu()}></div>
            <div className={isOpen ? "burgerWrapperOpen_dealer" : "burgerWrapperClose_dealer"}>
                <div style={{position: 'absolute',width:' calc(100% - 20px)'}}>
                    <div onClick={() => toggleMenu()} className="burger-close_dealer">
                        <span></span>
                    </div>
                    <li>
                        <div className="burgerProfile_dealer">
                            {
                                store.user?.image?
                                    <CustomIMG className="burgerProfilePictures_dealer" src={store.user?.image} />:
                                    <img className="burgerProfilePictures_dealer" src={randomizeDefaultProfileImgPath(store.user.displayedName)} alt="User Logo"/>
                            }


                            <div className="burgerFullName_dealer">
                                <span className="dealer">Поставщик</span>
                                <span className="DealerName">{store.user.lastName}</span>
                                <span className="DealerName">{store.user.firstname}</span>
                                <span className="DealerName">{store.user.vatherName}</span>
                            </div>
                        </div>
                    </li>
                </div>
                <div id={"seller-burger-scroll"} className="burgerPadding_dealer">
                    <li className="burgerLi_dealer">
                        <Link className="a-link_burger_dealer" to={SpaRoutes.SUPPLIER_PROFILE} onClick={() => toggleMenu()}>
                            <div className="img_nameLi_container_dealer">
                                <img src={"/Pictures/user.svg"}/>
                                Учетные данные
                            </div>
                            <img src={"/Pictures/Vector (Stroke).svg"}/>
                        </Link>
                    </li>
                    <div className="burgerListCenter_dealer">
                        {list}
                    </div>
                    <div className="burgerFooter_dealer">
                        <span>Тех. поддержка</span>
                        <a className="a-link_burger_dealer a-link_burger_footer_dealer" href="tel:+79999999999">
                            {store.helpNumber}
                        </a>
                        <li className="burgerLi_dealer">
                            <Link className="a-link_burger_dealer a-link_burger_footer_dealer" to={"/logout"} onClick={() => toggleMenu()}>
                                    Выйти
                            </Link>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Burger;