import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../../index";

import CustomNavigateHeader from "../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {ICategoriesLE} from "../../../models/models";
import {ISubCategoriesLE} from "../../../models/models";
import './Catalog.css'
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import SupplierCardProduct from "../../ProtectedPages/Customer/Cart/SupplierCardProduct/SupplierCardProduct";
import {ISupplierCardObj} from "../../../models/supplier/supplier";
import {ICard} from "../../../models/product/productCard";
import ProductCard from "../../customUI/ProductCard/ProductCard";
import CustomSwiper from "../../customUI/CustomSwiper/CustomSwiper";
import HomeService from "../../../services/HomeService";
import FilterForCatalog from "./FilterForCatalog";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import CartEmpty from "../../customUI/NoProductsPage/NoProductsPage";
import NoProductsPage from "../../customUI/NoProductsPage/NoProductsPage";
import CustomSwiperWithLazyLoading from "../../customUI/CustomSwiperWithLazyLoading/CustomSwiperWithLazyLoading";
import Searchbar from "../../customUI/Searchbar/Searchbar";
import {ToogleVisible} from "../../customUI/Searchbar/functions";

const placeholder = ''

interface IitemArray {
    catName: string;
    catId: number;
    cards: ICard[];
    isAuth: boolean;
}

function CatPage() {
    let {id, search} = useParams()
    const [name, setname] = useState<string>(placeholder)
    const [itemArray, setitemArray] = useState<IitemArray[]>([]);
    const [nothingShown, setNothingShown] = useState<boolean>(false);
    const {store} = useContext(Context)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [searchword, setSearchword] = useState<string>("");
    // const location = useLocation();
    const navigate = useNavigate()
    if (search === undefined) {
        search = "";
    }

    useEffect(() => {
        setSearchword(search ? search.slice(1) : "");
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getData = () => {
        if(store.filterInfo.topId && store.filterInfo.topId != "-1"){
            store.DataLoadingON()
            HomeService.indexCat(store.filterInfo.topId, store.filterInfo).then(response => {
                let items = (response.data.list.map((itemArray: IitemArray) => {
                    itemArray.cards = itemArray.cards.map((card: ICard) => {
                        card.visible = [true];
                        card.content = {Name: card.name_of_product, Id: card.productId, Seller: card.supply_company};
                        return card;
                    })
                    let oldCards = itemArray.cards;
                    itemArray.cards = ToogleVisible(searchword, ["Name", "Id", "Seller"], ["", "", ""], oldCards)
                    return itemArray;
                }));
                let itemsSorted = items.sort((a: any, b: any) => {
                    if (a.sorter < b.sorter) return -1
                    if (a.sorter > b.sorter) return 1
                    return 0
                })
                setitemArray(itemsSorted)
                setname(response.data.name);
            })
                .catch(() => {

                })
                .finally(() => {
                    store.DataLoadingOFF()
                    setDataLoaded(true)
                    getVisibleCards(search ? search.slice(1) : "")
                })
        }
    }

    const getVisibleCards = (search: string) => {
        setitemArray((prevState) => {
            let newState: IitemArray[];
            newState = prevState.map((itemArray) => {
                let oldCards = itemArray.cards;
                itemArray.cards = ToogleVisible(search, ["Name", "Id", "Seller"], ["", "", ""], oldCards)
                return itemArray
            })
            return newState;
        })
    }

    useEffect(() => {
        getVisibleCards(searchword)
    }, [searchword])

    useEffect(() => {
        if (itemArray.length == 0) {
            setNothingShown(true);
        } else {
            if (itemArray.every((item) => {
                if (item.cards.length === 0) {
                    return true
                }
                return item.cards.every((card) => {
                    return card.visible?.every(i => !i)
                })
            })) {
                setNothingShown(true);
            } else {
                setNothingShown(false);
            }
        }
    }, [itemArray, searchword])

    const changeHandler = (text: string) => {
        navigate(SpaRoutes.CATEGORY + `/${id}` + `/:${text}`)
    }

    const ListKategories: any = () => {
        var list = [];
        for (let i = 0; i < itemArray.length; i++) {
            let displayed = itemArray[i].cards.filter((card) => {
                return card.visible?.every(i => i)
            })
            if (displayed.length > 0) {
                list.push(
                    <CustomSwiperWithLazyLoading
                        key={itemArray[i].catId}
                        data={displayed}
                        title={itemArray[i].catName}
                        toSub={true}
                        id={itemArray[i].catId.toString()}
                        isAuth={store.roles.includes('Customer') || store.roles.includes("Dealer")}
                        searchWord={searchword}
                    />
                )
            }
        }
        return (list)
    }

    const goBack = () => {
        let filter = store.filterInfo
        filter.topId = "-1"
        filter.topCatName = "Категория"
        filter.subId = "-1"
        filter.subCatName = "Подкатегория"
        store.FiltersSave(filter.topId, filter.topCatName, filter.subId, filter.subCatName, filter.selectedIds)
        navigate(SpaRoutes.CATALOG_INDEX + `/:` + (searchword? searchword : ""))
    }

    return (
      <div id={"CatSelect"}>
          <div id={"CatSelectBox"}>
              <CustomNavigateHeader
                trashClickHandler={() => {
                }}
                title={name}
                withTrash={false}
                onClick={() => goBack()}
              />
          </div>
          <div className="sticky-search-filter" data-isauth={store.isAuth  && store.roles.includes('Dealer')}>
              <div className={"catalog_searchBox"}>
                  <Searchbar
                    changeHandler={changeHandler}
                    initialText={"Искать"}
                    value={search.slice(1)}
                  />
              </div>
              <FilterForCatalog
                onUpdate={() => getData()}
                searchword={searchword}
              />
          </div>
              {!store.isDataLoading &&
              nothingShown ?
                <NoProductsPage
                  tittle={"Не смогли ничего для вас найти"}
                  text={"Не нашли по вашему запросу ни одного продукта, попробуйте изменить параметры фильтра или очистить его"}
                  searchWord={searchword}
                />
                :
                <div id={"swiper-container-catalog"}>
                    {ListKategories && <ListKategories/>}
                </div>
              }
          </div>
          )
          }
          
          export default CatPage;

