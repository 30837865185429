import React, {useContext, useRef, useState} from 'react';
import "./TermsPopup.css"
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import Checkbox01 from "../../../../customUI/checkbox01/checkbox01";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import {Context} from "../../../../../index";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

interface TermsPopupProps {
    dropAddressId: string
    onClose: () => void
    onUpdate: () => void
    data: {
        visible?: boolean
        description?: string
    }
}

const TermsPopup = ({dropAddressId, onClose, data, onUpdate}:TermsPopupProps) => {
    const {store} = useContext(Context);
    const [visible, setVisible] = useState(data?.visible??false);
    const [description, setDescription] = useState(data?.description??"");

    const updateTerms = () => {
        store.DataLoadingON()
        let data = {
            Info1: dropAddressId,
            Bool: visible,
            Info2: description,
        }

        CabinetSellerService.updateTerms(data)
            .then(() => {
                alert("Данные были изменены")
                onUpdate()
                onClose()
            })
            .catch((e) => {alert("Что-то пошло не так")})
            .finally(() => store.DataLoadingOFF())
    }

    const ref = useRef(null)

    useOnClickOutside(ref, () => onClose())

    return (
        <div className="termsPopup-container">
            <div className="termsPopup-content" ref={ref}>
                <CustomNavigateHeader title={"Условия доставки"} onClick={() => onClose()} withTrash={false}/>

                <textarea
                    className={"termsPopup-description"}
                    onChange={(e) => setDescription(e.target.value) }
                    value={description}
                    placeholder={"Напишите ваши условия доставки и самовывоза для ваших пунктов выдачи"}
                />
                <Checkbox01 checked={visible} changeHandler={(e) => setVisible(e.target.checked)} title={"Показывать условия доставки"} />
                <button className="termsPopup-button" onClick={() => updateTerms()}>
                    Сохранить изменения
                </button>
            </div>
        </div>
    );
};

export default TermsPopup;