import React, {forwardRef} from 'react';
import {Popconfirm} from 'antd';
import type {PopconfirmProps} from 'antd';

const CustomAntPopconfirm = forwardRef<HTMLElement, PopconfirmProps>((
        {
            title,
            onConfirm,
            onCancel,
            children,
            ...rest
        }, ref) => {
        return (
            <Popconfirm
                title={title}
                onConfirm={onConfirm}
                onCancel={onCancel}
                cancelText={"Отмена"}
                okText={"Ок"}
                ref={ref as React.MutableRefObject<any>}
                {...rest}
            >
                {children}
            </Popconfirm>
        );
    }
);

export default CustomAntPopconfirm;