import './CustomPreviewMask.css'

export const CustomPreviewMask = () => {
	return (
		<>
			<div className='product-preview-mask-text'>
				<img src='/Pictures/Eye01.png'/>
				<p>Просмотр</p>
			</div>
		</>
	)
}