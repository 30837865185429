import React, { useState } from "react";
import "./MyOrdersDropdown.css";
import {IMyOrders} from "../../../models/models";

interface Interface {
    propsnext: IMyOrders;
    title: string;
}

function MyOrdersDropdown(propsnext: Interface) {
    const [details, setDetails] = useState(false);
    const label = propsnext.propsnext.orderProducts?.length + " " + propsnext.title;

    // Вывод списка
    const list = propsnext.propsnext.orderProducts?.flatMap((item: any) =>
        (
            <tr className={"myorders-table-row"} key={item.productId}>
                <td className={"myorders-columns myorders-first-column"}>·</td>
                <td className={"myorders-columns myorders-second-column"}>{item.name}</td>
                <td className={"myorders-columns myorders-third-column"}>{item.amount} шт.</td>
                <td className={"myorders-columns myorders-fourth-column"}>
                    {Intl.NumberFormat("ru", { style: "currency", currency: "RUB" }).format(item.price)}
                </td>
            </tr>
        )
    );

    return (
        <div className="myorders-dropdown-wrapper">
            <div className="myorders-header-wrapper">
                <div className={"myorders-header-button-wrapper"} onClick={() => setDetails((prev) => !prev)}>
                    <span className={"myorders-header-item-title"}>{label}</span>
                    <img className={"myorders-header-button"} src={"/Pictures/Vector 8.svg"} alt="Toggle Details" />
                </div>
            </div>
            {details && (
                <div className={"myorders-main-wrapper"}>
                    <table className={"myorders-table"}>
                        <tbody>{list}</tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default MyOrdersDropdown;
