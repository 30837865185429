import {Link} from "react-router-dom"
import '../../../../../index.css'
import './UserMenu.css'
import '../../UserLayout.css'
import React, {useEffect, useState} from "react";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {useCookies} from "react-cookie";
import RegionSelector from "../../../../customUI/RegionSelector/RegionSelector";
import HomeService from "../../../../../services/HomeService";
import {IRegion} from "../../../../../models/models";

function UserMenu() {
    const [cookies, setCookie, removeCookie] = useCookies(['RegionName']);
    const [regions, setRegions] = useState<IRegion[]>([])

    useEffect(()=>{
        HomeService.GetRegions()
            .then((resp) => {
                // устанавливаем регионы
                setRegions(resp.data.regions);
            });
    },[])

    return(
        <ul id={"usermenu-container"}>
            <li><Link className={"login a-link button"} to={SpaRoutes.LOGIN}>
                ВОЙТИ
            </Link></li>
            <div id="menu-location">
                {/*<img id="img-menu-location" src="/Pictures/Локация.svg"/>*/}
                <RegionSelector regions={regions} name={cookies?.RegionName?cookies.RegionName:"Пермский край"}/>
            </div>
            <li><Link className={"register a-link button"} to={SpaRoutes.REGISTER}>
                РЕГИСТРАЦИЯ
            </Link></li>
        </ul>
    )
}export default UserMenu;