import React, {useRef} from 'react';
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Terms} from "../../../../models/response/getProductInfoResponse";
import "./ProductTermsPopup.css"
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

interface ITermsPopup {
    onClose: () => void;
    terms?: Terms;
}

const ProductTermsPopup = ({onClose, terms}: ITermsPopup) => {

    const ref = useRef(null);

    useOnClickOutside(ref, () => onClose());

    return (
        <div className={"productTermsPopup-container"}>
            <div className={"productTermsPopup-content"} ref={ref}>
                <CustomNavigateHeader title={"Условия доставки"} type={"close"} onClick={() => onClose()}/>

                <div className={"productTermsPopup-description"}>
                    {terms?.description}
                </div>
            </div>
        </div>
    );
};

export default ProductTermsPopup;