import "./PopUpTrainingCart.css";
import React, {useContext, useEffect, useRef, useState} from "react";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import {getParsedDate, removeGorod} from "../../../../../functions/functions";
import {Context} from "../../../../../index";
import {Link, useNavigate} from "react-router-dom";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import AdminService from "../../../../../services/AdminService";
import {CustomPreviewMask} from "../../../../customUI/CustomPreviewMask/CustomPreviewMask";
import {Image} from "antd";

interface IPopUpTrainingCart {
    id: string,
    title: string,
    blocked: boolean,
    cityName: string,
    organisator?: string,
    organisatorId?: string,
    startDate: string,
    endDate: string,
    description: string,
    imageUrl: string,
    editable?: boolean | undefined,
    attending?: boolean,
    setAttending?: (id: string) => void
    onUpdateData?: () => void,
    setBlocked: (arg0: boolean, id: string) => void,
    closePopUp: () => void,
    openPopUpAttenders: () => void
}

export const PopUpTrainingCart = ({
                                      id,
                                      title,
                                      blocked,
                                      setBlocked,
                                      cityName,
                                      attending,
                                      setAttending,
                                      organisator,
                                      organisatorId,
                                      startDate,
                                      endDate,
                                      description,
                                      imageUrl,
                                      editable,
                                      onUpdateData,
                                      closePopUp,
                                      openPopUpAttenders
                                  }: IPopUpTrainingCart) => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    const [attend, setAttend] = useState(attending)
    const handleTrainingCartClick = async () => {
        if (store.roles.includes('Dealer')) {
            CabinetSellerService.postSignUpForEvent({"Id": id}).then((response) => {
                    if (setAttending) {
                        setAttending(id);
                        setAttend(true)
                    }
                }
            )
        } else if (store.roles.includes('Customer')) {
            CabinetBuyerService.postSignUpForEvent({"Id": id}).then(() => {
                if (setAttending) {
                    setAttending(id);
                    setAttend(true)
                }
            })
        }
    }


    const EventDelete = () => {
        if (window.confirm("Вы точно хотите удалить событие?")) {
            store.DataLoadingON()
            let mydata = {"Info1": id}
            CabinetSellerService.postDeleteCalendarEvent(mydata)
                .then((response) => {
                    if (response.status == 200) {
                        window.location.reload();
                        alert("Событие удалено")
                    }
                })
                .catch((error) => {
                    if (error.response.status === 500) {
                        alert("Такого события нет")
                    } else {
                        alert("Что-то пошло не так")
                    }
                })
                .finally(() => {
                    store.DataLoadingOFF()
                })
        }
    }

    const EventBlock = () => {
        if (window.confirm("Вы точно хотите заблокировать событие?")) {
            let mydata = {
                "id": id,
                "blocked": true
            }
            setBlocked(true, id);
            AdminService.postUpdateNewCalendarEvent(mydata)
        }
    }

    const EventUnBlock = () => {
        if (window.confirm("Вы точно хотите возобновить событие?")) {
            let mydata = {
                "id": id,
                "blocked": false
            }
            setBlocked(false, id);
            AdminService.postUpdateNewCalendarEvent(mydata)
        }
    }

    const [scrollbarPosition, setScrollbarPosition] = useState(0);
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const [showScrollbar, setShowScrollbar] = useState(true);
    const handleContentScroll = (e: any) => {
        const container = e.target;
        setScrollbarPosition((container.scrollTop / (container.scrollHeight - container.clientHeight)) * 100);
    };

    useEffect(() => {
        const container = containerRef.current;
        const content = contentRef.current;

        const updateScrollbar = () => {
            // @ts-ignore
            if (content.scrollHeight <= content.clientHeight) {
                setShowScrollbar(false);
            } else {
                setShowScrollbar(true);
            }
        };
        // @ts-ignore

        window.addEventListener('resize', updateScrollbar);

        updateScrollbar(); // Проверяем сразу после монтирования

        return () => {
            // @ts-ignore
            window.removeEventListener('resize', updateScrollbar);
        };
    }, []);

    return (
        <div className="popup-trainingCart-wrapper">
            {store.roles.some((role) => {
                return role === "Admin" || role === "Dealer"
            }) ? <div style={{height: "30px"}}></div> : <></>}

            <div className="popup-trainingCart-container">
                <div className="popup-trainingCart-top">
                    <div className="popup-trainingCart-top-block">
                        <label>
                            <div>
                                <img src="/Pictures/Loc.svg" alt="..."/>
                                {removeGorod(cityName)}
                            </div>

                            <b>
                                {startDate &&
                                endDate ? (getParsedDate(startDate, false, false) === getParsedDate(endDate, false, false) ?
                                            `${getParsedDate(startDate, false, false)}` :
                                            `${getParsedDate(startDate, false, false)} - ${getParsedDate(endDate, false, false)}`
                                    )

                                    : getParsedDate(startDate, false, false)
                                }
                            </b>
                            <b>
                                {organisator}
                            </b>
                        </label>

                    </div>
                    <div className="popup-trainingCart-top-block-buttons">
                        {editable && <>
                            <button onClick={openPopUpAttenders} className={"popup-trainingCart-buttonAttenders"}/>
                            <button onClick={() => navigate(SpaRoutes.ADDTRAINING + `/${id}`)}
                                    className={"popup-trainingCart-buttonEdit"}/>
                            <button onClick={EventDelete} className={"popup-trainingCart-buttonDelete"}/>
                        </>
                        }
                        {store.roles.includes('Admin') && (!blocked ?
                                <button onClick={EventBlock} className={"popup-trainingCart-buttonBlock"}/>
                                :

                                <button onClick={EventUnBlock} className={"popup-trainingCart-buttonUnBlock"}/>
                        )
                        }

                        <div onClick={closePopUp} className={"popup-trainingCart-buttonClose"}/>
                    </div>

                </div>
                <div className="popup-trainingCart-content">


                    <div className="popup-trainingCart-bottom-content" onScroll={handleContentScroll}
                         ref={contentRef} data-show-scroll={showScrollbar}>
                        <div>
                            {blocked && <div style={{
                                position: "relative",
                                top: "40px",
                                padding: "10px",
                                backgroundColor: "black",
                                color: "red",
                                fontSize: "20px",
                                maxHeight: "16px",
                                minHeight: "16px",
                                marginBottom: "-36px"
                            }}>
                                ЗАБЛОКИРОВАНО
                            </div>}
                            <div className={"popup-trainingCart-image-container"}>
                                {imageUrl &&
                                    <img alt={"Увеличить"} src={"/Pictures/find.svg"} className={"popup-trainingCart-image-increase"}/>
                                }
                                <Image
                                    rootClassName='popup-trainingCart-bottom-img'
                                    src={imageUrl ? store.imgServer + imageUrl : "/Pictures/Обучение.svg"}
                                    preview={{
                                        maskClassName: 'product-preview-mask',
                                        mask: imageUrl? <CustomPreviewMask/> : undefined,
                                    }}
                                    fallback={'/Pictures/supplierNoImage.svg'}
                                />
                            </div>

                        </div>
                        <div className="popup-trainingCart-bottom-block">
                            {editable || organisatorId === store.user.id ?
                                <Link to={store.roles[0] == "Dealer" ?
                                    SpaRoutes.SUPPLIER_CHAT + "/:/:5/:" + id :
                                    SpaRoutes.CHAT_DIALOG_PAGE + "/:/:5/:" + id}>Чат</Link>
                                : attend ?
                                    <Link to={store.roles[0] == "Dealer" ?
                                        SpaRoutes.SUPPLIER_CHAT + "/:/:5/:" + id :
                                        SpaRoutes.CHAT_DIALOG_PAGE + "/:/:5/:" + id}>Чат</Link>
                                    : <div onClick={handleTrainingCartClick}>Пойти</div>
                            }
                            <p>
                                {title}
                            </p>
                            <label>
                                {description}
                            </label>
                        </div>
                    </div>
                    {showScrollbar &&
                        <div className="popup-trainingCart-bottom-scrollbar">
                            <div className="popup-trainingCart-bottom-thumb"
                                 style={{top: `${scrollbarPosition}%`}}></div>
                        </div>
                    }
                </div>

            </div>

        </div>
    );
};
