import React, {useContext, useEffect, useState} from 'react';
import "./AdminReservationReklama.css"
import RegionSelector from "../../../customUI/RegionSelector/RegionSelector";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {IRegion, IDateReservation, IReklama} from "../../../../models/models";
import CalendarReklama from "../../../Supplier_UI_kit/CalendarReklama/CalendarReklama";
import AdminReservationPopup from "./AdminReservationPopup";
import AdminService from "../../../../services/AdminService";
import AdminCalendarReklama from "../../../Supplier_UI_kit/AdminCalendarReklama/AdminCalendarReklama";
const AdminReservationReklama = () => {
    const {store} = useContext(Context)

    const [typeRek, setTypeRek] = useState(0)
    const [regions, setRegions] = useState<IRegion[]>()
    const [selectRegion, setSelectRegion] = useState<string>("global")
    const [currentDate, setCurrentDate] = useState({month: new Date().getMonth()+1, year: new Date().getFullYear()})

    const [datesReservations, setDatesReservations] = useState<IDateReservation[]>([])
    const [price, setPrice] = useState({userBonuses: 0, first: 0, other: 0})

    const [popup, setPopup] = useState<JSX.Element | null>(null)

    const [allReklama, setAllReklama] = useState<IReklama[]>([])

    const getRegions = () => {
        store.DataLoadingON()
        AdminService.getRegions4AllReservations(typeRek)
            .then((r) => setRegions(r.data))
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }

    const getUserReklama = () => {
        store.DataLoadingON()
        AdminService.getAllEntertainment4Reservations()
            .then((r) => setAllReklama(r.data))
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }

    const getReservations = () => {
        let data = {
            month: currentDate.month,
            year: currentDate.year,
            type: typeRek,
            regionId: selectRegion,
        }

        store.DataLoadingON()
        AdminService.getAllReservations(data)
            .then((r) => {
                setDatesReservations(r.data.dates.map((d: any) => {
                    console.log(d)
                    return {
                        day: d.day,
                        month: currentDate.month,
                        year: currentDate.year,
                        reservations: d.reservations,
                    }
                }))
                setPrice({userBonuses: r.data.totalBonus, first: r.data.price1, other: r.data.price2})
            })
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        getRegions()
        getUserReklama()
    }, [])

    useEffect(() => {
        getReservations()
    }, [currentDate, typeRek, selectRegion])

    return (
        <div className={"adminReservationReklama"}>
            <CustomNavigateHeader title={"Бронирование рекламы"} withTrash={false}/>
            <div className={"adminReservationReklama-types"}>
                <button className={"adminReservationReklama-type"} data-sel={typeRek===0} onClick={() => setTypeRek(0)}>Верхняя реклама</button>
                <button className={"adminReservationReklama-type"} data-sel={typeRek===1} onClick={() => setTypeRek(1)} disabled={selectRegion === "global"}>Нижняя реклама</button>
            </div>
            <div className={"adminReservationReklama-types-regions"}>
                <button className={"adminReservationReklama-type"}
                        data-sel={selectRegion === "global"}
                        onClick={() => setSelectRegion("global")}
                        disabled={typeRek === 1}
                >
                    Федеральная реклама  {regions&&regions[0].number !== 0&&`(${regions&&regions[0].number})`}
                </button>
                {regions?.map((r, index) => {
                    if(r.regionId !== "global")
                    {
                        return <button className={"adminReservationReklama-type"} key={index}
                                       data-sel={selectRegion === r.regionId}
                                       onClick={() => setSelectRegion(r.regionId)}
                        >
                            {r.name} {r.number !== 0&&`(${r.number})`}
                        </button>
                    }
                })}
            </div>

            <AdminCalendarReklama calendarEvent={datesReservations}
                             updateDropdown={(month, year) => setCurrentDate({month: month, year: year})}
                             type={typeRek}
                             selectRegion={selectRegion}
                             itemEventClick={(day, month, year, reservations) => {
                                 setPopup(<AdminReservationPopup reservations={reservations}
                                                                 day={day} month={month} year={year}
                                                                 onClose={() => setPopup(null)}
                                                                 userReklama={allReklama}
                                                                 price={price}
                                                                 onUpdate={() => {
                                                                     getReservations()
                                                                     getUserReklama()
                                                                     getRegions()
                                                                 }}
                                                                 type={typeRek}
                                                                 selectRegion={selectRegion}

                                 />)
                             }}
                             userReklama={allReklama}
            />

            {popup && popup}
        </div>
    );
};

export default AdminReservationReklama;