import { FC, useContext } from 'react'
import { Image } from 'antd'
import { Context } from '../../../index'
import type { IGalleryImages } from '../../../models/response/getProductInfoResponse'
import './ImageWithSlides.css'
import { CustomPreviewMask } from '../CustomPreviewMask/CustomPreviewMask'


interface ImageWithSlides {
	mainImage: string;
	galleryImages: IGalleryImages[] | undefined;
}

export const ImageWithSlides: FC<ImageWithSlides> = ({ mainImage, galleryImages }) => {
	const { store } = useContext(Context)
	
	const items = galleryImages?.map(item => store.imgServer + item.imageUrl) as string[]

	return (
		<>
			<Image.PreviewGroup
				items={items}
			>
				<Image
					rootClassName='product-image'
					src={mainImage}
					preview={{
						maskClassName: 'product-preview-mask',
						mask: <CustomPreviewMask/>,
					}}
					fallback={'/Pictures/supplierNoImage.svg'}
				/>
			</Image.PreviewGroup>
		</>
	)
}