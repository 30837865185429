import React, { useContext, useEffect, useRef, useState } from "react";
import Message from "../Message/Message";
import "./allMessages.css";
import { Context } from "../../../../index";

interface IMessage {
    id: string;
    From: string;
    Message: string;
    Date: string;
    Name: string;
}

interface IProps {
    chat: IMessage[];
    infoList:{id:string,name:string}[];
    isGroupChat:boolean;
    updateChatMessage:(id:string,newText:string)=>void
    deleteChatMessage:(id:string)=>void
}

const AllMessages: React.FC<IProps> = ({ chat, infoList, isGroupChat,updateChatMessage,deleteChatMessage}: IProps) => {
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const { store } = useContext(Context);
    const [isPopupOpen, setIsPopupOpen] = useState(false);



    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [chat]);




    function compareDatesDays(date1:string, date2:string) {
        const parts1 = date1.split(' ');
        const parts2 = date2.split(' ');

        const dateParts1 = parts1[0].split('.');
        const dateParts2 = parts2[0].split('.');

        const day1 = parseInt(dateParts1[0]);
        const day2 = parseInt(dateParts2[0]);

        return day1 === day2;
    }

    const getMessages = ():any => {
        let messageBuffer = '';

        if(chat[0] !== undefined){
            let mainList:any[] = [];
            let properDate = chat[0].Date.split(" ")[0].split(chat[0]?.Date?.includes('.')?'.':'/')
            let list:any[] = [<div className={"message_date_notification"}>{properDate[0]+"."+properDate[1]+"."+properDate[2]}</div>,
                <Message
                    key={0 + chat[0].id}
                    chatRef={refChat}
                    id={chat[0].id}
                    message={chat[0].Message}
                    fromUserId={chat[0].From}
                    name={chat[0].Name}
                    Date={chat[0].Date}
                    updateChatMessage={updateChatMessage}
                    deleteChatMessage={deleteChatMessage}
                />
            ];

            for(let i=1;i<chat.length;i++){
                if(!compareDatesDays(chat[i].Date,chat[i-1].Date)){
                    mainList.push(list);
                    list = [];

                    let properDate = chat[i].Date.split(" ")[0].split(chat[i]?.Date?.includes('.')?'.':'/')
                    list.push(<div className={"message_date_notification"}>{properDate[0]+"."+properDate[1]+"."+properDate[2]}</div>)
                    list.push(
                        <Message
                            key={i + chat[0].id}
                            chatRef={refChat}
                            id={chat[i].id}
                            message={chat[i].Message}
                            fromUserId={chat[i].From}
                            name={chat[i].Name}
                            Date={chat[i].Date}
                            updateChatMessage={updateChatMessage}
                            deleteChatMessage={deleteChatMessage}
                        />);
                }
                else{
                    let message = chat[i].Message;
                    let dublicate = false;
                    messageBuffer = chat[i-1].Message;

                    if(message&&message.includes(store.HashForChat)){

                        if(messageBuffer === message)
                            dublicate = true;
                        else{
                            dublicate = false;
                            list.push(
                                <Message
                                    key={i + chat[0].id}
                                    chatRef={refChat}
                                    id={chat[i].id}
                                    message={chat[i].Message}
                                    fromUserId={chat[i].From}
                                    name={chat[i].Name}
                                    Date={chat[i].Date}
                                    updateChatMessage={updateChatMessage}
                                    deleteChatMessage={deleteChatMessage}
                                />
                            )
                        }
                    }

                    else{!dublicate &&
                        list.push(
                            <Message
                                key={i + chat[0].id}
                                chatRef={refChat}
                                id={chat[i].id}
                                message={chat[i].Message}
                                fromUserId={chat[i].From}
                                name={chat[i].Name}
                                Date={chat[i].Date}
                                updateChatMessage={updateChatMessage}
                                deleteChatMessage={deleteChatMessage}
                            />
                        )
                    }
                }
            }
            mainList.push(list);

            return mainList;
        }
    }



    const Popup: React.FC<{ infoList?:{id:string,name:string}[]; closePopup: () => void }> = ({ infoList, closePopup }) => {
        return (
            <div className="popup-messages">
                <div className="popupContent">
                    <ul>
                        {infoList?.map((item, index) => (
                            <li key={index + item.id}>{item.name}</li>
                        ))}
                    </ul>
                </div>
                <div className="popupClose" onClick={closePopup}>
                    Close
                </div>
            </div>
        );
    };

    const refChat = useRef(null)
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <div ref={refChat}>
            {
                (isGroupChat && infoList && infoList?.length===0)&&
                <div className="chat-header" onClick={togglePopup}>
                    <div className={"chat-header-div"}>{isPopupOpen ? "Close" : infoList?.toString()}</div>
                    {isPopupOpen && <Popup infoList={infoList} closePopup={togglePopup} />}

                </div>
            }
            {
                chat.length < 1 && <div className={"allMessages_empty"}>
                    Начните диалог
                </div>
            }
            <div style={{padding: "0 10px"}} className="allMessages">
                {getMessages()}
                <div ref={messagesEndRef}>{}</div>
                <div ref={messagesEndRef} />
            </div>
        </div>
    );
};

export default AllMessages;
