import React, {useEffect, useRef, useState} from 'react';
import {CustomIMG} from "../CustomIMG/CustomIMG";

interface LoadProductImage {
    onChange: (preview:string | undefined, file:File | undefined) => void,
    disable?: boolean,
    previewProps?: string,
    title?: string,
    canDelete?: boolean
    onDelete?: () => void
}

const LoadProductImage = ({onChange, disable, previewProps, title, onDelete, canDelete=false} : LoadProductImage) => {
    const [drag, setDrag] = useState(false)
    const [file, setFile] = useState<File>();
    const [preview, setPreview] = useState<string>();
    const inputRef = useRef(null)
    const dragOver = (e: any) => {
        e.preventDefault()
    }

    const dragEnter = (e: any) => {
        e.preventDefault()
        setDrag(true)
    }

    const dragLeave = (e: any) => {
        e.preventDefault()
        setDrag(false)
    }

    const fileDrop = (e: any) => {
        e.preventDefault()
        setDrag(false)
        setFile(e.dataTransfer.files[e.dataTransfer.files.length - 1])
    }

    const selectPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setFile(event.target.files[event.target.files.length - 1])
    }

    const onBtnPictureClick = () => {
        // @ts-ignore
        inputRef.current?.click()
    }

    useEffect(() => {
        if (!file) {
            return
        }
        const objectUrl = URL.createObjectURL(file)
        onChange(objectUrl, file)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    return (
        <div className={"productDetails-photo"}>
            <div className={"productDetails-hint-text"}>{title}</div>
            <div
                className={
                    "productDetails-photo-image" +
                    (drag && !disable
                        ? " productDetails-photo-image-dragEnter"
                        : "")
                }
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={!disable ? fileDrop : () => undefined}
            >
                {previewProps ? (
                    <CustomIMG src={previewProps} alt={"product picture"}/>
                ) : (
                    <div className={"productDetails-photo-image-text"}>
                        {!disable ? "Поместите сюда фото" : "Нет фото" }
                    </div>
                )}

                {canDelete && previewProps &&
                    <div className={"productDetails-photo-delete"} onClick={() => onDelete && onDelete()}>
                        <img src={"/Pictures/trashRed.svg"} alt={"delete"}/>
                    </div>
                }
            </div>
            {!disable&&
                <>
                    <input
                        ref={inputRef}
                        style={{display: "none"}}
                        type="file"
                        name={"profilePicture"}
                        onChange={selectPicture}
                        accept="image/*"
                    />
                    <button
                        className={"productDetails-photo-button"}
                        onClick={onBtnPictureClick}
                    />
                </>
            }
        </div>
    );
};

export default LoadProductImage;